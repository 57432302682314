import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import API from '../api/axios';

interface Section {
    header: string | null;
    content: string;
    order: number;
}

interface BlogPost {
    title: string;
    slug: string;
    image_url: string;
    read_more_link: string;
    translate_x: number;
    translate_y: number;
    sections: Section[];
}

class BlogStore {
    blogPosts: BlogPost[] = [];  // Stores all blog posts
    blogPost: BlogPost | null = null;  // Stores a single blog post

    constructor() {
        makeAutoObservable(this);
    }

    async fetchBlogPosts() {
        try {
            const response = await API.get<{ count: number, next: string | null, previous: string | null, results: BlogPost[] }>('/api/website/blog/');
            this.blogPosts = response.data.results;  // Update to handle the paginated format
            console.log(this.blogPosts);  // Log the fetched blog posts
        } catch (error) {
            console.error('Error fetching blog posts:', error);
        }
    }
    

    async fetchBlogPost(slug: string) {
        try {
            const response = await API.get<BlogPost>(`/api/website/blog/${slug}/`);
            this.blogPost = response.data;  // Update the single blog post state
            console.log(this.blogPost);  // Log the blog post
        } catch (error) {
            console.error('Error fetching blog post:', error);
            this.blogPost = null;
        }
    }
}

const blogStore = new BlogStore();
export default blogStore;
