import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ThickColorfulDivider } from '../../assets/images/Thick-Colorful-Divider.svg';
import HiringForm from './HiringForm';
import { Outlet } from 'react-router-dom';

const Hiring: React.FC = () => {
    return (
        <div>

            <Outlet />
            <HiringForm />
        </div>
    );
};

export default Hiring;

export const HiringIndexPage: React.FC = () => {
    return(
        <>
            <CareersSection />
            <JobOffersSection />
        </>
    )
};


const CareersSection: React.FC = () => {
  return (
    <div className="text-center p-4 md:p-8">
      <h1 className="text-3xl md:text-5xl text-sea-blue font-bold mb-4 md:mb-6">
        <span className="tracking-wide">JOIN THE BAY & BASIN TEAM!</span>
      </h1>
      <p className="mb-4 md:mb-6 text-sm md:text-md px-4 md:px-32">
        What is your passion? What do you love to do? What do you play at that most call work? We don't want mercenaries. We only hire patriots. People who do what they do for the love of it - the tough challenges and sweet victories. Only select the position that meets the criteria for you.
      </p>
      <ThickColorfulDivider className='w-full my-4' />
    </div>
  );
};


const JobOffersSection: React.FC = () => {
  const jobListings = [
    {
      title: 'Commercial Lines Account Manager',
      reportsTo: 'Lead Customer Service Representative',
      status: 'Non-Exempt/Full Time',
      salaryRange: '$20-30 per hour DOE',
      link: "/hiring/commercial-lines-account-manager"
    },
    {
      title: 'Commercial Lines Sales Representative',
      department: 'Commercial Lines',
      reportsTo: 'Owner',
      status: 'Non-Exempt/Full Time',
      link: "/hiring/commercial-lines-sales-representative"
    },
  ];

  return (
    <div className="p-8">
      <h2 className="text-5xl text-basin-brown font-regular mb-6 text-center">
        JOB OFFERS
      </h2>
      
      {jobListings.map((job, index) => (
        <React.Fragment key={index}>
          <div className="mb-4 text-sea-blue">
            <h3 className="font-regular text-xl tracking-[.15em] text-sea-blue">{job.title}</h3>
            <p>Reports to: {job.reportsTo}</p>
            <p>Status: {job.status}</p>
            {job.salaryRange && <p>{job.salaryRange}</p>}
            <a href={job.link} className="underline">Description</a>
          </div>
            <hr className="border-t-2 border-basin-light-brown mb-4" />
        </React.Fragment>
      ))}
    </div>
  );
};
