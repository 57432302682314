import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Import Link component from react-router-dom

interface ImageWithOverlayProps {
  image: ImageInfo;
}

export interface ImageInfo {
  src: string;
  overlayColor: string;
  opacity: number;
  text?: string;
  textPosition?: 'center' | 'bottom';
  isVisible?: boolean;
  href: string; // Link URL for navigation
}

const ImageWithOverlay: React.FC<ImageWithOverlayProps> = ({
  image
}) => {
  return (
    <Link to={image.href} className="w-full h-full cursor-pointer"> {/* Wrap content in Link */}
      <img src={image.src} alt="Dynamic Content" className="w-full h-full object-cover" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: (image.isVisible) ? image.opacity : 0 }}
        transition={{ duration: 0.3 }}
        className="absolute top-0 left-0 w-full h-full"
        style={{ backgroundColor: image.overlayColor }}
      >
        {image.text && (
          <div className={`absolute w-full px-4 py-2 text-center ${image.overlayColor === "#799daa"? 'text-white' : 'text-sea-blue'} ${image.textPosition === 'bottom' ? 'bottom-0' : 'top-1/2 transform -translate-y-1/2'}`}>
            <span className={`${image.textPosition === 'bottom' ? 'text-4xl font-maleo font-regular tracking-[.2em]' : 'text-base font-medium'}`}>{image.text}</span>
          </div>
        )}
      </motion.div>
    </Link>
  );
};

export default ImageWithOverlay;
