import React from 'react';

interface SideCloudBubbleProps {
  imageUrl: string;
  clipPath: string;
  translateX?: number;
  translateY?: number;
  brown?: boolean;
}

const SideCloudBubble: React.FC<SideCloudBubbleProps> = ({ imageUrl, brown = false, translateX = 0, translateY = 34.63, clipPath }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="292.755 0 878.265 723.72">
      <defs>
        <style>
          {`
            .SideCloudBubble-cls-1-${brown? 'brown' : ''} {
              fill: #${brown? 'EDD1B0' : '799dab'};
            }

            .SideCloudBubble-cls-1-${brown? 'brown' : ''}, .SideCloudBubble-cls-2 {
              stroke-width: 0px;
            }

            .SideCloudBubble-cls-3 {
              clip-path: url(#${clipPath});
            }

            .SideCloudBubble-cls-4 {
              fill: none;
              stroke: #3b5572;
              stroke-miterlimit: 10;
              stroke-width: 6px;
            }

            .SideCloudBubble-cls-2 {
              fill: #ede947;
            }
          `}
        </style>
        <clipPath id={clipPath}>
          <path className="SideCloudBubble-cls-4" d="M478.09,462.75c-31.24,10.4-67.01,4.53-93.7-18.59h0c-40.26-34.88-44.62-95.78-9.75-136.04l190.66-220.08c34.88-40.26,95.78-44.62,136.04-9.75h0c24,20.79,35.24,50.83,33.02,80.24l94.14-108.68c34.88-40.26,95.78-44.62,136.04-9.75h0c40.26,34.88,44.62,95.78,9.75,136.04l-333.51,384.99c-34.88,40.26-95.78,44.62-136.04,9.75h0c-31.73-27.49-41.16-71.16-26.65-108.13Z"/>
        </clipPath>
      </defs>
      <g>
        <path className="SideCloudBubble-cls-4" d="M809.71,295.36h0c43.79,39.42,47.33,106.88,7.9,150.67l-215.53,239.38c-39.42,43.79-106.88,47.33-150.67,7.9h0c-43.79-39.42-47.33-106.88-7.9-150.67l215.53-239.38c39.42-43.79,106.88-47.33,150.67-7.9Z"/>
        <g>
          <g className="SideCloudBubble-cls-3">
            <image width="992" height="555" transform={`translate(${translateX} ${translateY}) rotate(-1.93) scale(1.04)`} href={imageUrl}/>
          </g>
          <path className="SideCloudBubble-cls-4" d="M478.09,462.75c-31.24,10.4-67.01,4.53-93.7-18.59h0c-40.26-34.88-44.62-95.78-9.75-136.04l190.66-220.08c34.88-40.26,95.78-44.62,136.04-9.75h0c24,20.79,35.24,50.83,33.02,80.24l94.14-108.68c34.88-40.26,95.78-44.62,136.04-9.75h0c40.26,34.88,44.62,95.78,9.75,136.04l-333.51,384.99c-34.88,40.26-95.78,44.62-136.04,9.75h0c-31.73-27.49-41.16-71.16-26.65-108.13Z"/>
        </g>
        <circle className="SideCloudBubble-cls-2" cx="755.44" cy="40.16" r="23.61"/>
        <path className={`SideCloudBubble-cls-1-${brown? 'brown' : ''}`} d="M1135.71,283.13h0c43.79,39.42,47.33,106.88,7.9,150.67l-215.53,239.38c-39.42,43.79-106.88,47.33-150.67,7.9h0c-43.79-39.42-47.33-106.88-7.9-150.67l215.53-239.38c39.42-43.79,106.88-47.33,150.67-7.9Z"/>
    </g>
    </svg>
  );
};

export default SideCloudBubble;
