import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';

const TermsOfService: React.FC = () => {
  return (
    <>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">Terms of Service</h1>
      <h2 className="text-xl font-semibold">1. Introduction</h2>
      <p className="text-md text-gray-700 mt-2">
        Welcome to Bay and Basin Insurance. By accessing our site or using our services, you agree to be bound by the following terms and conditions.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">2. Use of Service</h2>
      <p className="text-md text-gray-700 mt-2">
        The services provided by Bay and Basin Insurance are for personal and non-commercial use. You may not use our services to sell a product or service, or to increase traffic to your business for commercial reasons, such as advertising sales.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">3. Your Account</h2>
      <p className="text-md text-gray-700 mt-2">
        If you use any Bay and Basin Insurance service, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.
      </p>

      <h2 className="text-xl font-semibold mt-4">4. Privacy</h2>
      <p className="text-md text-gray-700 mt-2">
        Please review our Privacy Policy, which also governs your visit to our website, to understand our practices.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">5. Communication</h2>
      <p className="text-md text-gray-700 mt-2">
        When you visit Bay and Basin Insurance or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on this site.
      </p>

      <h2 className="text-xl font-semibold mt-4">6. License and Site Access</h2>
      <p className="text-md text-gray-700 mt-2">
        Bay and Basin Insurance grants you a limited license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Bay and Basin Insurance.
      </p>

      <h2 className="text-xl font-semibold mt-4">7. User Content</h2>
      <p className="text-md text-gray-700 mt-2">
    You may post reviews, comments, and other content, send other communications, and submit suggestions, ideas, comments, questions,
    or other information, so long as the content is not illegal, obscene, threatening, defamatory, infringing of intellectual property rights, invasive of privacy, or otherwise injurious or objectionable. You must not post or transmit any content that you do not have the right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements).
    </p>

        <h2 className="text-xl font-semibold mt-4">8. Product Descriptions</h2>
      <p className="text-md text-gray-700 mt-2">
        Bay and Basin Insurance attempts to be as accurate as possible. However, we do not warrant that product descriptions or other content of this site is accurate, complete, reliable, current, or error-free.
      </p>

      <h2 className="text-xl font-semibold mt-4">9. Disclaimer of Warranties and Limitation of Liability</h2>
      <p className="text-md text-gray-700 mt-2">
        This site and all information, content, materials, products, and services included on or otherwise made available to you through this site are provided by Bay and Basin Insurance on an "as is" and "as available" basis, unless otherwise specified in writing. Bay and Basin Insurance makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, products, or services included on or otherwise made available to you through this site, unless otherwise specified in writing. You expressly agree that your use of this site is at your sole risk.
      </p>

      <h2 className="text-xl font-semibold mt-4">10. Disputes</h2>
      <p className="text-md text-gray-700 mt-2">
        Any dispute or claim relating in any way to your visit to Bay and Basin Insurance or to products or services sold or distributed by Bay and Basin Insurance or through bayandbasinins.com will be resolved by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify.
      </p>

      <h2 className="text-xl font-semibold mt-4">11. Applicable Law</h2>
      <p className="text-md text-gray-700 mt-2">
        By visiting bayandbasinins.com, you agree that the Federal Arbitration Act, applicable federal law, and the laws of the state where Bay and Basin Insurance headquarters are located, without regard to principles of conflict of laws, will govern these Terms of Service and any dispute of any sort that might arise between you and Bay and Basin Insurance.
      </p>

      <h2 className="text-xl font-semibold mt-4">12. Site Policies, Modification, and Severability</h2>
      <p className="text-md text-gray-700 mt-2">
        Please review our other policies, such as our Privacy Policy, posted on this site. These policies also govern your visit to Bay and Basin Insurance. We reserve the right to make changes to our site, policies, and these Terms of Service at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.
      </p>
    </div>
    <PrivacyPolicy/>
    </>
  );
};

export default TermsOfService;

