import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ThickColorfulDivider } from '../../../assets/images/Thick-Colorful-Divider.svg';
import { ReactComponent as ColorfulDivider } from '../../../assets/images/Colorful-Divider.svg';
import FooterSection from "./FooterSection";

const Footer: React.FC = () => {
  return (
    <footer className="w-full text-center">
      <ThickColorfulDivider className="w-full" />
      <h2 className="text-2xl md:text-5xl font-bold font-maleo mt-6 text-sea-blue">OUR PARTNERS</h2>
      <p className="text-sm md:text-xl font-regular font-maleo text-wave-blue mt-4 mx-auto px-4" style={{ maxWidth: '60ch' }}>
        We work with some of the most reputable insurance companies across the
        nation to ensure your business is provided the right coverage, at an
        affordable price without compromising service.
      </p>
      <Link to="/providers" className="mt-4 inline-block">
        <button className="bg-wave-blue text-white text-xs md:text-sm font-maleo py-2 px-4 rounded-full hover:bg-wave-blue-dark transition-colors duration-300">
          View All Providers
        </button>
      </Link>
      <ColorfulDivider className="w-full mt-8" />
      <FooterSection />
    </footer>
  );
}

export default Footer;
