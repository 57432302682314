import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LogoComponent, DesktopMenu, MobileMenuButton, MobileMenu } from './Components';



const Header: React.FC = () => {  
    const location = useLocation();
    const { pathname } = location;
  const menuItems = [
    { name: 'Home', path: '/home' },
    { name: 'About Us', path: '/about-us' },
    { name: "Insurance Services", path: '/insurance-services'},
    { name: "Contact Us", path: '/contact-us'},
    { name: "Carrier Partners", path: '/carrier-partners'},
    { name: "Blog", path: '/blog'},
    // ... other menu items
  ];

  const mobileMenuItems = [
    ...menuItems, 
    { name: "Client Portal", path: "/client-portal" },
    { name: "Hiring", path: "/hiring" }
  ];

  

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return pathname !== '/login' ?  (
    <header className="relative bg-white pb-4 border-yellow-300">
      <div className="flex justify-between items-center w-full pl-4 md:pl-6 lg:pl-8">
        <div className="flex items-center">
          <LogoComponent /> 
        </div>
        <div className="flex-grow"></div> {/* This empty div pushes the following elements to the right */}
        <div className="flex items-center space-x-4">
          <DesktopMenu menuItems={menuItems} />
          <div className='pr-4'>
            <MobileMenuButton toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

          </div>
        </div>
    </div>
    <MobileMenu isMenuOpen={isMenuOpen} menuItems={mobileMenuItems} setIsMenuOpen={setIsMenuOpen} />
  </header>
) : <></>;
};

export default Header;
