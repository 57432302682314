import React from 'react';
import CloudBubble from '../utilities/CloudBubble';
import { ReactComponent as RoundedDivider } from '../../assets/images/Rounded-Divider.svg';
import { ReactComponent as LogoWatermark } from '../../assets/images/Logo-Watermark.svg'
import { ReactComponent as ColorfulLogo } from '../../assets/images/Colorful_Logo.svg'
import QuoteForm from '../utilities/QuoteForm';
import { useNavigate } from 'react-router-dom';
// import EmploymentSection from './Employment';

const FirstSection: React.FC = () => {
    // Utility component to conditionally render line breaks
    const Br = ({ showOnMobile = false }) => (
        <span className={`${showOnMobile ? '' : 'hidden sm:inline'}`}><br/></span>
    );

    return (
        <div className="relative w-full">
            <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/background_business.svg"} alt="Background" className="w-full block" />
    
            {/* Content container with conditional positioning */}
            <div className="flex flex-col sm:absolute top-0 left-0 w-full p-8 z-10 h-full">
                {/* Top section with text and cloud bubble - stays at the top */}
                <div className="flex flex-col sm:flex-row items-start">
                    <div className="text-center justify-center md:text-left md:flex-grow text-sunset-text-orange font-maleo font-medium tracking-[.1em] text-xl sm:tracking-[.3em] sm:text-3xl">
                        Small, <Br />
                        Mid-size, <Br />
                        Or large, <Br showOnMobile={true} /> {/* Hide BR on small screens */}
                        WE'RE IN THE <Br />
                        BUSINESS <Br />
                        OF PROTECTING <Br />
                        YOUR BUSINESS
                    </div>
                    <div className="flex-none w-full sm:w-1/4 sm:-translate-x-full hidden sm:block">
                        <CloudBubble imageUrl={"https://bay-and-basin-bucket.s3.amazonaws.com/IS_roundtable.webp"} />
                    </div>
                </div>
    
                {/* Text section moved below the image on small screens */}
                <div className="mt-8 sm:absolute sm:bottom-12 bg-[rgba(60,85,114,.4)] text-white p-4 text-center">
                    <h2 className="font-maleo tracking-[.2em] font-regular text-2xl sm:text-4xl">Why Is It Important?</h2>
                    <p className="font-maleo tracking-[.2em] font-regular text-sm sm:text-xl mt-4">
                        As a business owner, you take risks, make investments, and employ others to work for you and help your company grow. These steps are exciting, but they can set you up for major loss when accidents happen. It is important to protect yourself, your finances, and your employees in the event that something goes wrong.
                    </p>
                </div>
            </div>
        </div>
    );
    
};

const insuranceOptions = [
    "GENERAL LIABILITY INSURANCE",
    "AGRIBUSINESS INSURANCE",
    "BUSINESS INTERRUPTION",
    "BUSINESS OWNER'S PACKAGE",
    "BUILDER'S RISK",
    "COMMERCIAL AUTO INSURANCE",
    "COMMERCIAL BUILDING & PROPERTY",
    "CONTRACTORS INSURANCE",
    "CYBER",
    "EMPLOYMENT PRACTICES LIABILITY",
    "EQUIPMENT BREAKDOWN INSURANCE",
    "PROFESSIONAL LIABILITY",
    "EXCESS LIABILITY COVERAGE",
    "GROUP DISABILITY INSURANCE",
    "GROUP HEALTH INSURANCE",
    "GROUP LIFE INSURANCE",
    "GROUP VISION INSURANCE",
    "GROUP DENTAL INSURANCE",
    "INLAND MARINE",
    "POLLUTION",
    "SURETY PERFORMANCE BID BONDS",
    "WORKERS' COMPENSATION"
];

const slugify = (text: string) =>
    text.toLowerCase()
        .replace(/['’]/g, '') // Remove single quotes
        .replace(/[^a-z0-9]+/g, '-') // Replace spaces and other non-alphanumeric characters with hyphens
        .replace(/^-+|-+$/g, ''); // Trim leading and trailing hyphens

const InsuranceOptions = () => {
    const navigate = useNavigate();

    const handleNavigate = (slug: string) => {
        navigate(`/insurance-services/business-insurance/${slug}`);
    }

    return (
        <div className="flex flex-col gap-2 md:gap-4">
            {insuranceOptions.map((option, index) => {
                const slug = slugify(option);
                return (
                    <div key={option} className="flex items-center relative">
                        <div className="w-10 h-10 bg-white border-2 border-sea-blue rounded-full z-20"></div>
                        <button onClick={() => handleNavigate(slug)} className={`text-xs sm:text-sm md:text-base lg:text-lg flex-1 py-2 px-4 rounded-full font-maleo font-regular uppercase tracking-wider transition-colors duration-300 
                                            ${index % 3 === 0 ? 'bg-wave-blue text-sea-blue' : 
                                             index % 3 === 1 ? 'bg-white text-sea-blue' : 
                                             'bg-basin-light-brown text-sea-blue'} 
                                            hover:bg-sea-blue hover:text-white
                                            ml-[-16px] sm:ml-[-12px] md:ml-[-8px] lg:ml-0`}>
                            {option}
                        </button>
                    </div>
                );
            })}
        </div>
    );
}

const OrganizedSection: React.FC = () => {
    return (
        <div className="relative w-full overflow-hidden z-10">
            {/* Background Image */}
            <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/Sun.webp"} alt="Decorative Background" className='absolute z-0 bottom-0 w-full' />

            {/* Watermark Logo */}
            <div className="absolute -left-20 top-0 ">
                <LogoWatermark className="w-4/5 opacity-75 mx-auto" />
            </div>

            {/* Content Heading */}
            <div className="pt-10 px-4 md:px-20 text-center">
                <h2 className='font-maleo tracking-[.1em] md:tracking-[.2em] text-sunset-text-orange text-3xl md:text-4xl'>BUSINESS INSURANCE</h2>
                <p className='font-maleo tracking-[.1em] md:tracking-[.2em] p-4 md:p-20 text-sea-blue'>Explore the best coverage options for your business! When you work with Bay and Basin Insurance Services, you will get all the coverage you need. Multiple options of coverage are noted below:</p>
            </div>

            {/* Insurance Options and Quote Form Layout */}
            <div className="flex flex-col md:flex-row justify-between items-start px-4 md:px-20">
                {/* Left Column for Insurance Options */}
                <div className="w-full md:w-1/2 z-10 pb-10 md:pb-20">
                    <InsuranceOptions />
                </div>

                {/* Right Column for Quote and Contact Info */}
                <div className="w-full md:w-1/2 space-y-8 pb-10 md:-mt-20">
                    <div className='w-3/4 mx-auto'>
                        <QuoteForm />
                    </div>
                    <ColorfulLogo className="w-1/4 mx-auto" />
                    <div className='text-center'>
                        <h2 className='text-sunset-text-orange font-maleo tracking-[.1em] md:tracking-[.2em] text-3xl md:text-4xl'>TALK TO AN AGENT</h2>
                        <p className='text-sunset-text-orange font-maleo tracking-[.1em] md:tracking-[.2em]'>
                            Or call for immediate assistance.
                        </p>
                        <p className='text-sea-blue font-maleo tracking-[.1em] md:tracking-[.2em] text-xl md:text-2xl'>
                            831-540-4583
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const truckingOptions: string[] = [
    'Trucking Insurance', 
    'Motor Truck Cargo', 
    'Physical Damage', 
    'Truck Liability'
];

const FinalSection: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigate = (slug: string) => {
        navigate(`/insurance-services/business-insurance/${slug}`);
    }

    return(
        <div className="relative w-full"> 
            {/* Background Image */}
            <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/commercial_truck.webp"} alt="Commercial Truck" className='relative z-0 bottom-0 w-full' />
            <div className="absolute w-full flex justify-center top-0 mt-0 md:-mt-10 ">
                <div className="bg-[#F9F1EA] rounded-[20px] pb-1 w-4/5 py-6 items-end inline-flex justify-center z-40">
                    <h2 className="text-sunset-text-orange text-center text-2xl md:text-4xl font-maleo font-medium tracking-[.1em] md:tracking-[.2em] uppercase">Commercial Trucking</h2>
                </div>
            </div>
            <div className="absolute flex flex-wrap justify-center items-center w-full top-10 md:top-16 py-4 px-4 z-30">
                {truckingOptions.map(buttonText => (
                <button 
                    onClick={() => handleNavigate(slugify(buttonText))}
                    key={buttonText}
                    className="bg-white text-sea-blue tracking-[.2em] font-maleo uppercase px-3 md:px-6 py-1 md:py-2 rounded-full transition duration-300 m-2
                            hover:bg-sunset-text-orange hover:text-white hover:font-medium">
                    {buttonText}
                </button>
                ))}
            </div>

        </div>
    );
}



const BusinessInsurance: React.FC = () => {
    return (
        <section style={{ position: 'relative', marginTop: '10%', overflow: 'visible' }}> {/* Changed to <section> for semantic markup */}
            <FirstSection />

            <RoundedDivider className="w-4/5 relative left-1/2 bottom-0 z-30 mt-[-20px] transform -translate-x-1/2" />

            <OrganizedSection />

            <FinalSection />
        </section>
    );
};

export default BusinessInsurance;



