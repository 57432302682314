import { motion } from "framer-motion";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {ReactComponent as Logo} from '../../../assets/images/header/Logo.svg';
import { ReactComponent as HeaderLine } from '../../../assets/images/header/Header-Line.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/images/header/Facebook-Icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/header/Search-Icon.svg';
import { ReactComponent as HiringButton } from '../../../assets/images/header/Hiring.svg';
import { ReactComponent as ClientPortalButton } from '../../../assets/images/header/Client-Portal-Button.svg';

interface MenuItem {
    name: string;
    path: string;
  }
interface MenuProps {
    menuItems: MenuItem[];
  }
  
  interface ToggleProps {
    toggleMenu: () => void;
    isMenuOpen: boolean;
  }
  interface MobileMenuProps extends MenuProps {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const topBunVariants = {
    open: { rotate: 45, y: 6, x: 1, backgroundColor: '#3C5572' },
    closed: { rotate: 0, y: 0, x: 0, backgroundColor: '#3C5572' },
  };
  const centerBunVariants = {
    open: 'bg-transparent',
    closed: 'bg-sea-blue' ,
  };
  const bottomBunVariants = {
    open: { rotate: -45, y: -6, x: 1, backgroundColor: '#3C5572' },
    closed: { rotate: 0, y: 0, x: 0, backgroundColor: '#3C5572'},
  };

  const mobileMenuVariants = {
    open: {
      scaleY: 1,
      opacity: 1,
      display: 'block',
      transition: {
        scaleY: { duration: 0.3, delay: 0.1 },
        opacity: { duration: 0.2, delay: 0.1 }
      }
    },
    closed: {
      scaleY: 0,
      opacity: 0,
      transition: {
        scaleY: { duration: 0.2 },
        opacity: { duration: 0.2 }
      },
      transitionEnd: { display: 'none' }
    }
  };

  const menuItemVariants = {
    open: { y: 0, opacity: 1 },
    closed: { y: -20, opacity: 0 }
  };
  
  export const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, menuItems, setIsMenuOpen }) => (
    <motion.nav 
      initial="closed"
      animate={isMenuOpen ? "open" : "closed"}
      variants={mobileMenuVariants}
      className="absolute top-28 right-4 lg:hidden bg-transparent bg-opacity-5 backdrop-filter backdrop-blur-lg rounded-lg p-4 z-40 origin-top-right"
    >
      <ul className="flex flex-col items-center space-y-4">
        {menuItems.map((item, index) => (
          <motion.li key={index} variants={menuItemVariants} className="text-center flex items-center space-x-3 py-2">
            <Link to={item.path} onClick={() => setIsMenuOpen(false)} className="flex items-center space-x-2 hover:text-heritage-gold">
              <span className="font-maleo text-black text-lg hover:text-sea-blue">{item.name}</span>
            </Link>
          </motion.li>
        ))}
      </ul>
    </motion.nav>
  );
  

  

  export const MobileMenuButton: React.FC<ToggleProps> = ({ toggleMenu, isMenuOpen }) => (
    <button className="lg:hidden z-50 bg-transparent rounded-none transition-none hover:scale-100 hover:shadow-none" onClick={toggleMenu}>
      <motion.div className="w-8 h-0.5 bg-white mb-1" variants={topBunVariants} animate={isMenuOpen ? 'open' : 'closed'} />
      <motion.div className={"w-8 h-0.5 mb-1 flex " + (isMenuOpen ? centerBunVariants.open : centerBunVariants.closed)} />
      <motion.div className="w-8 h-0.5 bg-white" variants={bottomBunVariants} animate={isMenuOpen ? 'open' : 'closed'} />
    </button>
  );
  
  function getClassNames(isActive: boolean, colorIndex: number, colors: string[]) {
    const baseClasses = "rounded-full px-2 py-0 mr-1 text-lg lg:text-xl lg:text-2xl font-regular flex items-center cursor-pointer transition duration-200 ease-in-out font-maleo text-4xl";
    const colorClass = `bg-${colors[colorIndex % colors.length]}`;

    // Only apply 'text-white' if the active background color is 'sea-blue'
    const textColorClass = colorClass === 'bg-sea-blue' ? 'hover:text-white text-sea-blue' : 'text-sea-blue';

    // Apply hover effect only for inactive items and the background color only when active
    const hoverEffect = !isActive ? `hover:${colorClass}` : '';
    const textHoverEffect = hoverEffect !== '' ? `hover:${textColorClass}`: '';

    const backgroundColorClass = isActive ? colorClass : '';

    const activeClasses = `${textColorClass} ${backgroundColorClass} ${hoverEffect} ${textHoverEffect}`;

    return `${baseClasses} ${activeClasses}`;
}

    
  
export const DesktopMenu: React.FC<MenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const colors = [
    'sunset-yellow', 
    'sunset-dark-orange', 
    'wave-blue', 
    'sea-blue', 
    'basin-light-brown', 
    'basin-brown'
  ];

  return (
      <div className="hidden lg:flex z-30 flex-col items-end">
        <div className="absolute top-0 right-0 flex items-center justify-end z-40">
          <Link to="/hiring" className="relative z-10">
              <HiringButton className="w-44 h-16" />
              <div className="absolute inset-0 flex flex-col justify-center items-center text-center font-light font-maleo text-sunset-dark-orange leading-tight">
                  <span>We're</span>
                  <span>hiring</span>
              </div>
          </Link>
          <a href="https://www.facebook.com/BayandBasinInsurance/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="w-8 mx-4" />
          </a>            
          <SearchIcon className="w-8 mx-4 hidden" />
        </div>
        <nav className="mt-20 relative z-30">
          <motion.ul className="flex justify-end items-center relative space-x-2">
              {menuItems.map((item, index) => {
                  const isActive = location.pathname === item.path;
                  const className = getClassNames(isActive, index, colors);

                  return (
                    <Link to={item.path} key={item.name + index} className="menu-item-hover">
                      <motion.li 
                        whileHover={{ scale: 1.05 }}
                        className={`${className} text-sm md:text-base lg:text-lg whitespace-nowrap`} // Adjust text sizes responsively
                        initial={{ scale: 0.95 }}
                        animate={{ scale: 1 }}
                      >
                        {item.name}
                      </motion.li>
                    </Link>
                  );
              })}
              <HeaderLine className="absolute bottom-[-40px] right-0" style={{ height: '150%', width: '105%' }} />
          </motion.ul>
        </nav>
        <Link to="/client-portal" className="relative bottom-[-20px] z-10">
          <ClientPortalButton className="w-48 h-12" />
          <span className="absolute inset-0 flex items-center justify-center font-regular text-basin-light-brown text-xlg">Client Portal</span>
        </Link>
      </div>
  );   
};


  

  export const LogoComponent = () => (
    <Link to="/home" className="flex items-center z-20 pt-4">
      <Logo className="h-16 md:h-24"/>
      <motion.h1 className='hidden ml-4 md:ml-8 text-yellow-700 font-bold text-2xl lg:text-3xl text-center' whileHover={{ scale: 1.1 }}>
        Bay and Basin
      </motion.h1>
    </Link>
  );
  
  