import React, { useState } from 'react';
import { ReactComponent as RoundedDivider } from '../../assets/images/Rounded-Divider.svg'
import { ReactComponent as LocationBubbleSVG } from '../../assets/images/contactUs/LocationBubble.svg'; // Make sure the path is correct
import formStore from '../../stores/FormsStore';
import { observer } from 'mobx-react-lite';

const ContactForm: React.FC = observer(() => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        // Prepare the data in the structure expected by submitForm
        await formStore.submitForm({
            form_name: "Contact Us", // Name of the form as identified in your system
            data: {
                name,
                email,
                phone,
                message
            }
        });
    };

    return (
        <div className="relative font-maleo h-screen w-full">
            <img src="https://bay-and-basin-bucket.s3.amazonaws.com/Waves.webp" alt="Waves Background" className="absolute inset-0 w-full h-full object-cover" />
            <div className="absolute inset-0 flex justify-center items-center p-4">
                <div className="bg-white p-8 rounded-lg border-4 border-sea-blue shadow-lg w-full max-w-sm" style={{ borderRadius: '30px' }}>
                    <h3 className="text-3xl font-light mb-4 text-sea-blue text-center">
                        Contact Us
                    </h3>
                    <form onSubmit={handleSubmit} className="flex flex-col items-center">
                        <input
                            type="text"
                            placeholder="Name"
                            className="w-full mb-2 p-2 border rounded text-lg"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            className="w-full mb-2 p-2 border rounded text-lg"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            type="tel"
                            placeholder="Phone"
                            className="w-full mb-2 p-2 border rounded text-lg"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <textarea
                            placeholder="Your message here"
                            className="w-full mb-4 p-2 border rounded text-lg bg-basin-light-brown text-sea-blue"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows={4}
                            required
                        />
                        <button type="submit" className="bg-sea-blue text-white py-2 px-12 rounded-full font-light">
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
});   

const ContactInformation: React.FC = () => {
    return (
        <div className="relative bg-white p-8 text-center z-20">
            {/* Position the RoundedDivider at the top of the section */}
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-[37.5%] w-3/4 z-10">
                <RoundedDivider />
            </div>

            <h2 className="text-3xl font-bold text-sea-blue relative z-20">
                TALK TO AN AGENT
            </h2>
            <p className="text-lg text-sea-blue">
                Or call for immediate assistance.
            </p>
            <div className="text-xl text-sea-blue my-2">
                831-540-4583
            </div>
            <div className="flex justify-center">
                <LocationBubbleSVG className="w-8" />
            </div>
            <p className="text-lg text-sea-blue mt-2">
                Bay and Basin Insurance Services<br />
                371 Main St, Salinas, CA 93901, United States
            </p>
        </div>
    );
};


const ContactUs: React.FC = () => {
    return(
        <div>
            <ContactForm />
            <ContactInformation />
        </div>
    )
}


export default ContactUs;