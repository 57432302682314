import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import DetailedInsurance from "./DetailedInsurance";
import insuranceStore from "../../stores/InsuranceStore";

const DisplayInsurance: React.FC = observer(() => {
    const { option } = useParams<{ option: string }>();

    useEffect(() => {
        if (option) {
            insuranceStore.fetchInsuranceDetails(option);
        }
    }, [option]);

    if (insuranceStore.isLoading) {
        return <div>Loading...</div>;
    }

    if (insuranceStore.error) {
        return <div>Error: {insuranceStore.error}</div>;
    }

    if (!insuranceStore.insuranceData) {
        return <div>No data found.</div>;
    }

    const { image, title, description1, title2, description2, title3, description3, title4, descriptionItems } = insuranceStore.insuranceData;

    return (
        <div>
            <DetailedInsurance
                image={image}
                title={title}
                description1={description1}
                title2={title2}
                description2={description2}
                title3={title3}
                description3={description3}
                title4={title4}
                descriptionItems={descriptionItems}
            />
        </div>
    );
});

export default DisplayInsurance;
