import React, { useState } from 'react';

export interface FieldConfig {
    key: string;
    type: 'text' | 'email' | 'tel' | 'textarea' | 'select' | 'radio';
    placeholder?: string;
    label?: string;
    options?: string[];
    rows?: number;
}

interface FormProps {
    title: string;
    fields: FieldConfig[];
    submitButtonText?: string;
    className?: string;
    onSubmit: (formData: FormData) => void; // Add onSubmit prop for form submission
}

interface FormData {
    [key: string]: string | number | string[]; // Better suited for form inputs
}

const GenericForm: React.FC<FormProps> = ({ title, fields, submitButtonText, className, onSubmit }) => {
    const initialFormData = fields.reduce((acc, field) => {
        acc[field.key] = ''; // Default initialization for all fields including 'select'
        return acc;
    }, {} as FormData);

    const [formData, setFormData] = useState<FormData>(initialFormData)

    const handleChange = (fieldKey: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData(prev => ({ ...prev, [fieldKey]: event.target.value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(formData); // Invoke the onSubmit function passed via props with the current form data
    };

    return (
        <div className={`font-maleo text-sea-blue ${className} w-full max-w-md mx-auto p-4 bg-white rounded-lg border-4 border-sea-blue shadow-lg`}>
            <h3 className="text-2xl md:text-3xl font-light mb-4 text-center">
                {title}
            </h3>
            <form className="flex flex-col items-center w-full" onSubmit={handleSubmit}>
                {fields.map((field) => {
                    switch (field.type) {
                        case 'textarea':
                            return (
                                <textarea
                                    key={field.key}
                                    placeholder={field.placeholder}
                                    className="w-full mb-4 p-2 border rounded text-lg bg-basin-light-brown text-sea-blue"
                                    value={formData[field.key]}
                                    onChange={handleChange(field.key)}
                                    rows={field.rows || 3}
                                    required
                                />
                            );
                        case 'select':
                            return (
                                <select
                                    key={field.key}
                                    className="w-full mb-4 p-2 border rounded text-lg"
                                    value={formData[field.key]}
                                    onChange={handleChange(field.key)}
                                    style={{ color: formData[field.key] ? '#3C5572' : 'grey' }}
                                    required
                                >
                                    <option value="" disabled selected={!formData[field.key]}>Select</option>
                                    {field.options?.map((option, idx) => (
                                        <option key={idx} value={option}>{option}</option>
                                    ))}
                                </select>
                            );
                        case 'radio':
                            return (
                                <div key={field.key} className="w-full mb-4 p-2">
                                    <span className="block mb-2">{field.label}</span>
                                    <div className="flex flex-wrap justify-start items-center gap-4">
                                        {field.options?.map((option, idx) => (
                                            <label key={idx} className="flex items-center space-x-2">
                                                <input
                                                    type="radio"
                                                    name={field.key}
                                                    value={option}
                                                    checked={formData[field.key] === option}
                                                    onChange={handleChange(field.key)}
                                                    required
                                                    className="text-blue-600 focus:ring-blue-500 focus:ring-offset-2"
                                                />
                                                <span>{option}</span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            );
                        default:
                            return (
                                <input
                                    key={field.key}
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    className="w-full mb-2 p-2 border rounded tracking-[.2em] text-lg"
                                    value={formData[field.key]}
                                    onChange={handleChange(field.key)}
                                    required
                                />
                            );
                    }
                })}
                <button
                    type="submit"
                    className="bg-sea-blue text-white py-2 px-12 rounded-full font-light text-center"
                    style={{ display: 'inline-block', width: 'auto', textAlign: 'center' }}
                >
                    {submitButtonText || 'SUBMIT'}
                </button>
            </form>
        </div>
    );
};

export default GenericForm;
