import { motion } from "framer-motion";
import React, { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import formStore from "../../stores/FormsStore";


const GetStartedSection: React.FC = () => {
    const handleResumeUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            toast.info('Uploading file: ' + file.name);
            // Implement file upload logic here
        } else {
            toast.error('No file selected');
            // Handle the case where no file is selected, if necessary
        }
    };

    return (
      <div className="flex flex-col items-center justify-center py-24"> {/* Full screen height and centering */}
        <div className="text-center"> {/* Center text and elements vertically and horizontally */}
          <h2 className="text-5xl font-regular text-basin-brown">
            GET STARTED
          </h2>
          {/* <p className="text-sea-blue my-4">
            <span>If you're uploading a resumé - please feel free to skip any of the questions you've already answered in your resumé. <br/>Required fields will prompt you when you are submitting.</span>
          </p>
          <label htmlFor="resume-upload" className="text-sea-blue underline cursor-pointer">
            Upload Your Resume
          </label>
          <input 
            type="file" 
            id="resume-upload" 
            style={{ display: 'none' }} 
            onChange={handleResumeUpload}
            accept=".pdf,.doc,.docx" 
          /> */}
        </div>
      </div>
    );
};

const HiringForm: React.FC = () => {
  const [personalInfo, setPersonalInfo] = useState<PersonalInformationData>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        streetAddress: '',
        city: '',
        zip: '',
        addressLine2: '',
        state: '',
        country: ''
    });
  const [positionInfo, setPositionInfo] = useState<PositionInformationData>({
        position: '',
        startDate: '',
        desiredPay: '',
        employmentType: '',
    });
  const [educations, setEducations] = useState<IEducation[]>([]);
  const [references, setReferences] = useState<IReference[]>([]);
  const [employmentHistory, setEmploymentHistory] = useState<IEmployment[]>([]);
  const [hasAgreed, setHasAgreed] = useState(false);


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!hasAgreed) {
          toast.error('You must agree to the disclaimer before submitting.');
          return;
      }
      const formData = {
          personalInfo,
          positionInfo,
          educations,
          references,
          employmentHistory
      };
      // Submit formData to your API or handling function
      await formStore.submitForm({ form_name: "Hiring Form", data: formData });
  };

    return (
      <div>
        <GetStartedSection />
        <div className="flex justify-center items-center min-h-screen">
          <form onSubmit={handleSubmit} className="bg-white p-10 rounded-xl border-[3px] border-sea-blue space-y-8 max-w-4xl">
            <PersonalInformation onChange={setPersonalInfo} />
            <PositionInformation onChange={setPositionInfo} />
            <EducationInformation onChange={setEducations} />
            <References onChange={setReferences} />
            <EmploymentHistory onChange={setEmploymentHistory} />
            <Disclaimer onAgree={setHasAgreed} />
            <SubmitButton />
          </form>
        </div>
      </div>
    );
  };

  
  const EmploymentTypeSelector: React.FC<{ onChange: (type: string) => void }> = ({ onChange }) => {
    const [selectedType, setSelectedType] = useState('');

    const employmentTypes = [
        { id: 'full-time', label: 'Full Time' },
        { id: 'part-time', label: 'Part Time' },
        { id: 'seasonal-temporary', label: 'Seasonal / Temporary' }
    ];

    const handleChange = (type: string) => {
        setSelectedType(type);
        onChange(type);  // Notify the parent component of the change
    };

    return (
        <div className="mt-4">
            <fieldset>
                <legend className="text-xl font-regular text-sea-blue">Employment Desired:</legend>
                <div className="flex flex-col mt-2">
                    {employmentTypes.map((type) => (
                        <label key={type.id} className="flex items-center mt-2 text-sea-blue">
                            <input
                                type="radio"
                                name="employmentType"
                                value={type.id}
                                checked={selectedType === type.id}
                                onChange={() => handleChange(type.id)}
                                className="hidden"
                            />
                            <span className={`relative rounded-full w-5 h-5 inline-block border-2 mr-2 ${selectedType === type.id ? 'border-sea-blue bg-sea-blue' : 'border-gray-400'}`}>
                                {selectedType === type.id && (
                                    <span className="absolute inset-0 flex items-center justify-center">
                                        <svg className="w-3 h-3 text-white fill-current" viewBox="0 0 20 20">
                                            <path d="M7.629 14.571L3.15 10.093l1.036-1.036 3.443 3.443L15.813 3.15l1.036 1.036-9.22 10.385z" />
                                        </svg>
                                    </span>
                                )}
                            </span>
                            <span className="ml-2">{type.label}</span>
                        </label>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};


  interface PositionInformationProps {
    onChange: (data: PositionInformationData) => void;
  }
  
  interface PositionInformationData {
    position: string;
    startDate: string;
    desiredPay: string;
    employmentType: string; // Assuming this comes from EmploymentTypeSelector
  }
  

  const PositionInformation: React.FC<PositionInformationProps> = ({ onChange }) => {
    const [formData, setFormData] = useState<PositionInformationData>({
        position: '',
        startDate: '',
        desiredPay: '',
        employmentType: '',  // Initial state, assuming a default or null value
    });

    // Update parent state whenever local state changes
    useEffect(() => {
        onChange(formData);
    }, [formData, onChange]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEmploymentTypeChange = (employmentType: string) => {
        setFormData(prev => ({
            ...prev,
            employmentType
        }));
    };

    const formInputClass = "relative block w-full";

    return (
        <fieldset>
            <legend className="text-2xl font-bold text-basin-light-brown mb-4">POSITION</legend>
            <div className="space-y-4">
                <input 
                    type="text" 
                    name="position" 
                    value={formData.position}
                    onChange={handleChange}
                    className={`${formInputClass} form-input mt-1 border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0 text-wave-blue`}
                    placeholder="Which Position Are You Applying For?"
                />
                <div className={formInputClass}>
                    <input 
                        type="date" 
                        name="startDate" 
                        value={formData.startDate}
                        onChange={handleChange}
                        className="form-input mt-1 w-full border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0 text-wave-blue placeholder-transparent"
                        placeholder="Available Start Date"
                    />
                </div>
                <input 
                    type="text" 
                    name="desiredPay" 
                    value={formData.desiredPay}
                    onChange={handleChange}
                    className={`${formInputClass} form-input mt-1 border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0 text-wave-blue`}
                    placeholder="Desired Pay"
                />
                <EmploymentTypeSelector onChange={handleEmploymentTypeChange} />
            </div>
        </fieldset>
    );
};


interface ReferencesProps {
  onChange: (references: IReference[]) => void;
}

interface IReference {
  name: string;
  company: string;
  title: string;
  phone: string;
}

const References: React.FC<ReferencesProps> = ({ onChange }) => {
  const formInputClass = "form-input mt-1 mb-4 block w-full border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0";
  const [references, setReferences] = useState<IReference[]>([
    { name: '', company: '', title: '', phone: '' }
  ]);

  useEffect(() => {
    onChange(references);  // Notify the parent component whenever the references state changes
  }, [references, onChange]);

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newReferences = [...references];
    newReferences[index][name as keyof IReference] = value;
    setReferences(newReferences);
  };

  const addReference = () => {
    setReferences([...references, { name: '', company: '', title: '', phone: '' }]);
  };

  const removeReference = (index: number) => {
    const newReferences = references.filter((_, i) => i !== index);
    setReferences(newReferences);
  };

  const buttonVariants = {
    hover: { scale: 1.05, boxShadow: "0px 0px 8px rgb(255, 255, 255)" },
    tap: { scale: 0.95 }
  };

  return (
    <fieldset>
      <legend className="text-2xl font-bold text-basin-light-brown mb-4">REFERENCES</legend>
      {references.map((reference, index) => (
        <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <input 
              type="text" 
              name="name" 
              placeholder="Name" 
              value={reference.name}
              onChange={(e) => handleChange(index, e)}
              className={formInputClass} 
            />
            <input 
              type="text" 
              name="company" 
              placeholder="Company" 
              value={reference.company}
              onChange={(e) => handleChange(index, e)}
              className={formInputClass} 
            />
          </div>
          <div>
            <input 
              type="text" 
              name="title" 
              placeholder="Title" 
              value={reference.title}
              onChange={(e) => handleChange(index, e)}
              className={formInputClass} 
            />
            <input 
              type="text" 
              name="phone" 
              placeholder="Phone" 
              value={reference.phone}
              onChange={(e) => handleChange(index, e)}
              className={formInputClass} 
            />
          </div>
          <motion.button
            type="button"
            whileHover="hover"
            whileTap="tap"
            variants={buttonVariants}
            onClick={() => removeReference(index)}
            className=" w-1/4 bg-basin-brown hover:bg-basin-light-brown text-white font-bold py-2 px-4 rounded"
          >
            Remove
          </motion.button>
        </div>
      ))}
      <motion.button
        type="button"
        whileHover="hover"
        whileTap="tap"
        variants={buttonVariants}
        onClick={addReference}
        className="bg-wave-blue hover:bg-sea-blue text-white font-bold py-2 px-4 rounded mt-2"
      >
        Add Another Reference
      </motion.button>
    </fieldset>
  );
};

interface EmploymentHistoryProps {
  onChange: (jobs: IEmployment[]) => void;
}

interface IEmployment {
    employer: string;
    datesEmployed: string;
    jobTitle: string;
    contactInfo: string;
}

  const EmploymentHistory: React.FC<EmploymentHistoryProps> = ({ onChange }) => {
    const formInputClass = "form-input mt-1 block w-full border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0";
    const [jobs, setJobs] = useState<IEmployment[]>([
        { employer: '', datesEmployed: '', jobTitle: '', contactInfo: '' }
    ]);

    useEffect(() => {
        onChange(jobs);  // Notify the parent component whenever the jobs state changes
    }, [jobs, onChange]);

    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newJobs = [...jobs];
        newJobs[index][name as keyof IEmployment] = value;
        setJobs(newJobs);
    };

    const addJob = () => {
        setJobs([...jobs, { employer: '', datesEmployed: '', jobTitle: '', contactInfo: '' }]);
    };

    const removeJob = (index: number) => {
        const newJobs = jobs.filter((_, i) => i !== index);
        setJobs(newJobs);
    };

    const buttonVariants = {
        hover: { scale: 1.1, transition: { duration: 0.3 } },
        tap: { scale: 0.9 }
    };

    return (
        <fieldset>
            <legend className="text-2xl font-bold text-basin-light-brown mb-4">EMPLOYMENT HISTORY</legend>
            {jobs.map((job, index) => (
                <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <input
                            type="text"
                            name="employer"
                            placeholder="Employer"
                            value={job.employer}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                        <input
                            type="text"
                            name="datesEmployed"
                            placeholder="Dates Employed"
                            value={job.datesEmployed}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="jobTitle"
                            placeholder="Job Title"
                            value={job.jobTitle}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                        <input
                            type="text"
                            name="contactInfo"
                            placeholder="Contact Info"
                            value={job.contactInfo}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                    </div>
                    <motion.button
                        type="button"
                        whileHover="hover"
                        whileTap="tap"
                        variants={buttonVariants}
                        onClick={() => removeJob(index)}
                        className="mt-2 bg-basin-brown hover:bg-basin-light-brown text-white font-bold py-2 px-4 rounded w-1/4"
                    >
                        Remove
                    </motion.button>
                </div>
            ))}
            <motion.button
                type="button"
                whileHover="hover"
                whileTap="tap"
                variants={buttonVariants}
                onClick={addJob}
                className="mt-2 bg-wave-blue hover:bg-sea-blue text-white font-bold py-2 px-4 rounded"
            >
                Add Another Job
            </motion.button>
        </fieldset>
    );
};

  interface IEducation {
    schoolName: string;
    yearsAttended: string;
    major: string;
    location: string;
    degree: string;
  }
  
  interface EducationInformationProps {
    onChange: (educations: IEducation[]) => void;
  }
  
  const EducationInformation: React.FC<EducationInformationProps> = ({ onChange }) => {
    const formInputClass = "form-input mt-1 block w-full border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0";
    const [educations, setEducations] = useState<IEducation[]>([
      { schoolName: '', yearsAttended: '', major: '', location: '', degree: '' }
    ]);
  
    // Propagate changes to the parent component
    useEffect(() => {
      onChange(educations);
    }, [educations, onChange]);
  
    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newEducations = [...educations];
        newEducations[index][name as keyof IEducation] = value;
        setEducations(newEducations);
    };
  
    const addEducation = () => {
        setEducations([...educations, { schoolName: '', yearsAttended: '', major: '', location: '', degree: '' }]);
    };
  
    const removeEducation = (index: number) => {
        const newEducations = educations.filter((_, i) => i !== index);
        setEducations(newEducations);
    };
  
    const buttonVariants = {
      hover: { scale: 1.1, transition: { duration: 0.2 } },
      tap: { scale: 0.95 }
    };
  
    return (
        <fieldset>
            <legend className="text-2xl font-bold text-basin-light-brown mb-4">EDUCATION</legend>
            {educations.map((education, index) => (
                <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <input
                            type="text"
                            name="schoolName"
                            placeholder="School Name"
                            value={education.schoolName}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                        <input
                            type="text"
                            name="yearsAttended"
                            placeholder="Years Attended"
                            value={education.yearsAttended}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                        <input
                            type="text"
                            name="major"
                            placeholder="Major"
                            value={education.major}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            value={education.location}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                        <input
                            type="text"
                            name="degree"
                            placeholder="Degree Received"
                            value={education.degree}
                            onChange={(e) => handleChange(index, e)}
                            className={formInputClass}
                        />
                    </div>
                    <motion.button
                        type="button" 
                        whileHover="hover"
                        whileTap="tap"
                        variants={buttonVariants}
                        className="bg-basin-brown hover:bg-basin-light-brown text-white font-bold py-2 px-4 rounded w-1/4"
                        onClick={() => removeEducation(index)}
                    >
                        Remove
                    </motion.button>
                </div>
            ))}
            <motion.button
                type="button"
                whileHover="hover"
                whileTap="tap"
                variants={buttonVariants}
                className="bg-wave-blue hover:bg-sea-blue text-white font-bold py-2 px-4 rounded"
                onClick={addEducation}
            >
                Add More Education
            </motion.button>
        </fieldset>
    );
  };
  
  interface PersonalInformationProps {
  onChange: (data: PersonalInformationData) => void;
}

interface PersonalInformationData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  streetAddress: string;
  city: string;
  zip: string;
  addressLine2?: string;
  state: string;
  country: string;
}



const PersonalInformation: React.FC<PersonalInformationProps> = ({ onChange }) => {
  const formInputClass = "form-input mt-1 mb-4 block w-full border-b-[1px] border-sea-blue focus:border-sea-blue focus:ring-0";
  const [formData, setFormData] = useState<PersonalInformationData>({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      streetAddress: '',
      city: '',
      zip: '',
      addressLine2: '',
      state: '',
      country: ''
  });

  useEffect(() => {
      onChange(formData); // Propagate changes to the parent component whenever formData changes
  }, [formData, onChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({
          ...prev,
          [name]: value
      }));
  };

  return (
      <>
          <fieldset>
              <legend className="text-2xl font-bold text-basin-light-brown mb-4">PERSONAL INFORMATION</legend>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <input type="text" name="firstName" className={formInputClass} placeholder="First Name" value={formData.firstName} onChange={handleChange} />
                  <input type="text" name="lastName" className={formInputClass} placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
                  <input type="email" name="email" className={formInputClass} placeholder="Email" value={formData.email} onChange={handleChange} />
                  <input type="text" name="phone" className={formInputClass} placeholder="Phone" value={formData.phone} onChange={handleChange} />
              </div>
          </fieldset>

          <fieldset>
              <legend className="text-2xl font-bold text-basin-light-brown mb-4">ADDRESS</legend>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                      <input type="text" name="streetAddress" className={formInputClass} placeholder="Street Address" value={formData.streetAddress} onChange={handleChange} />
                      <input type="text" name="city" className={formInputClass} placeholder="City" value={formData.city} onChange={handleChange} />
                      <input type="text" name="zip" className={formInputClass} placeholder="ZIP / Postal Code" value={formData.zip} onChange={handleChange} />
                  </div>
                  <div>
                      <input type="text" name="addressLine2" className={formInputClass} placeholder="Address Line 2" value={formData.addressLine2} onChange={handleChange} />
                      <input type="text" name="state" className={formInputClass} placeholder="State / Province / Region" value={formData.state} onChange={handleChange} />
                      <input type="text" name="country" className={formInputClass} placeholder="Country" value={formData.country} onChange={handleChange} />
                  </div>
              </div>
          </fieldset>
      </>
  );
};

interface DisclaimerProps {
  onAgree: (agreed: boolean) => void;
}


const Disclaimer: React.FC<DisclaimerProps> = ({ onAgree }) => {
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
      onAgree(agreed);  // Notify the parent component about the agreement status
  }, [agreed, onAgree]);

  return (
      <fieldset className="mt-6">
          <legend className="text-xl font-bold text-gray-700 mb-2">DISCLAIMER</legend>
          <p className="text-sm mb-4">
              I Certify That My Answers Are True And Complete To The Best Of My Knowledge. If This Application Leads To Employment, I Understand That False Or Misleading Information In My Application Or Interview May Result In My Employment Being Terminated.
          </p>
          <label className="flex items-start">
              <input 
                  type="checkbox" 
                  className="form-checkbox h-5 w-5 text-blue-600 mt-1 mr-2"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  required // This makes the field mandatory
              />
              <span className="text-gray-700 text-sm">
                  Yes, I agree and certify my answers.
              </span>
          </label>
      </fieldset>
  );
};

  const SubmitButton: React.FC = () => {
    // Define motion variants for the button
    const buttonVariants = {
      initial: { scale: 1 },
      hover: {
        scale: 1.05,
        textShadow: "0px 0px 8px rgba(255,255,255,0.8)",
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)"
      },
      tap: {
        scale: 0.95
      }
    };
  
    return (
      <motion.button
        type="submit"
        initial="initial"
        whileHover="hover"
        whileTap="tap"
        variants={buttonVariants}
        className="mt-8 bg-sea-blue text-white rounded-xl px-6 py-2 text-lg font-light hover:bg-sea-blue-dark transition duration-200 ease-in-out transform"
      >
        Submit
      </motion.button>
    );
  };
  
  export default HiringForm;
  