import React from "react";

const logos = [
  'https://bay-and-basin-bucket.s3.amazonaws.com/Ameritas.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/BHHC.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/CHUBB.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/CNA.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/CalChoiceLogo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/CompWest.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/DD.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Humana.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Liberty_Mutual.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Metlife.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Nationwide-logo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Progressive-logo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Prudential-Financial-logo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/RepublicIndemnity.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Safeco_Logo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/The_Hartford.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Travelers-logo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/VSP_Logo.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/Zenith.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/amtrust.svg',
  'https://bay-and-basin-bucket.s3.amazonaws.com/berkeley.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/blue-cross-blue-shield.svg',
  'https://bay-and-basin-bucket.s3.amazonaws.com/icw-group.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/nip_group.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/omaha-national.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/omaha.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/principal.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/state-fund.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/tokiomarine.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/united_ag.webp',
  'https://bay-and-basin-bucket.s3.amazonaws.com/unum.webp'
]

const Carriers: React.FC = () => {
  return (
    <div className="bg-white p-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {logos.map((logo, index) => (
            <div key={index} className="flex justify-center items-center p-2">
              <img src={logo} alt={`Logo ${index + 1}`} className="max-h-12" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carriers;
