import React from 'react';

interface CircleBubbleProps {
    imageUrl: string;
    clipPath: string;
    scale?: number;
    translateX?: number;
    translateY?: number;
    profile?: boolean;
    blue?: boolean;
}

const CircleBubble: React.FC<CircleBubbleProps> = ({ imageUrl, clipPath, profile = false, scale = .24, translateX = -641.04, translateY = 99.13, blue= false }) => {
        return(        
        <svg id="Circle_2" data-name="Circle 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700.18 850.75">
          <defs>
            <style>
              {`
                .circleBubble-1 {
                  clip-path: url(#${clipPath});
                }
                .circleBubble-2 {
                  fill: #3b5572;
                }
                .circleBubble-2, .circleBubble-3, .circleBubble-4 {
                  stroke: #3b5572;
                  stroke-miterlimit: 10;
                  stroke-width: 6px;
                }
                .circleBubble-5 {
                  fill: #799daa;
                  stroke-width: 0px;
                }
                .circleBubble-3 {
                  fill: none;
                }
                .circleBubble-4-${blue? 'blue' : ''} {
                  fill: #${blue? '3C5572': 'fbac44'};
                }
              `}
            </style>
            <clipPath id={clipPath}>
              <circle className="circleBubble-3" cx="288.97" cy="419.06" r="285.97"/>
            </clipPath>
          </defs>
          <g id="Circle_1" data-name="Circle 1">
            <path className="circleBubble-3" d="M661.87,338.29h0c43.79,39.42,47.33,106.88,7.9,150.67l-215.53,239.38c-39.42,43.79-106.88,47.33-150.67,7.9h0c-43.79-39.42-47.33-106.88-7.9-150.67l215.53-239.38c39.42-43.79,106.88-47.33,150.67-7.9Z"/>
            <circle className="circleBubble-5" cx="584.3" cy="234.76" r="23.61"/>
            { !profile && <path className="circleBubble-2" d="M421.49,422.4h0c43.79,39.42,47.33,106.88,7.9,150.67l-215.53,239.38c-39.42,43.79-106.88,47.33-150.67,7.9h0c-43.79-39.42-47.33-106.88-7.9-150.67l215.53-239.38c39.42-43.79,106.88-47.33,150.67-7.9Z"/>}
            <path className={`circleBubble-4-${blue? 'blue' : ''}`} d="M532.39,30.4h0c43.79,39.42,47.33,106.88,7.9,150.67l-215.53,239.38c-39.42,43.79-106.88,47.33-150.67,7.9h0c-43.79-39.42-47.33-106.88-7.9-150.67L381.72,38.3c39.42-43.79,106.88-47.33,150.67-7.9Z"/>
            <g>
              <g className="circleBubble-1">
                <image width="6094" height="4063" transform={`translate(${translateX} ${translateY}) scale(${scale})`} href={imageUrl}/>
              </g>
              <circle className="circleBubble-3" cx="288.97" cy="419.06" r="285.97"/>
            </g>
          </g>
        </svg>
      );
    };

export default CircleBubble;
