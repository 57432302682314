import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ImageWithOverlay, { ImageInfo } from './ImageWithOverlay';

interface ImagePairWithOverlayProps {
  image1: ImageInfo;
  image2: ImageInfo;
  showToggles?: boolean
}

const ImagePairWithOverlay: React.FC<ImagePairWithOverlayProps> = ({ image1, image2, showToggles = false }) => {
  const location = useLocation();
  const [hoveredImage, setHoveredImage] = useState<string | null>(null);

  const determineVisibility = (path: string): boolean => {
    if (hoveredImage && hoveredImage !== path) {
      return false;
    }
    return location.pathname === path || !!hoveredImage;
  };

  return (
    <div className="flex justify-around items-center w-full mb-4">
      <div className='w-1/2'>
      <div className=" aspect-159 border-4 border-sea-blue relative" onMouseEnter={() => setHoveredImage(image1.href)} onMouseLeave={() => setHoveredImage(null)}>
        <ImageWithOverlay
          image={{
            ...image1,
            isVisible: determineVisibility(image1.href)
          }}
        />
      </div>
        {showToggles && (
            <div className="bg-sea-blue text-white text-center text-4xl py-4 rounded-bl-3xl rounded-br-3xl border-4 border-sea-blue">
            <span className="tracking-widest">BUSINESS<br />INSURANCE</span>
          </div>


        )}
      </div>
      
        <div className='w-1/2'>
          <div className="aspect-159 border-4 border-sea-blue relative" onMouseEnter={() => setHoveredImage(image2.href)} onMouseLeave={() => setHoveredImage(null)}>
          <ImageWithOverlay
            image={{
              ...image2,
              isVisible: determineVisibility(image2.href)
            }}
          />
        </div>

          {showToggles && (
            <div className="bg-sunset-light-orange text-sea-blue text-center text-4xl py-4 rounded-bl-3xl rounded-br-3xl border-4 border-sea-blue">
              <span className='font-maleo text-4xl'>LIFE &<br /> MORE</span>
            </div>
          )}
        </div>
    </div>
  );
};

export default ImagePairWithOverlay;
