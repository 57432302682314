import React from "react";
import Carriers from "./Carriers"; // Import the Carriers component we previously created
import CloudBubble from "../utilities/CloudBubble"; // Import the CloudBubble component

const CarriersPage: React.FC = () => {

  return (
    <div className="px-10 py-5">
      <div className="flex flex-col lg:flex-row justify-between items-start">
        <div className="lg:flex-1 p-4">
          <h1 className="text-5xl font-regular text-basin-brown mb-4">WE REPRESENT</h1>
          <p className="text-md text-sea-blue">
            In order to provide you the best services, we work with only the best insurance carriers around. We’re proud to associate ourselves with the carriers below:
          </p>
        </div>
        <div className="lg:flex-1 p-4">
          <CloudBubble imageUrl={"https://bay-and-basin-bucket.s3.amazonaws.com/carrier-partners-cloud.webp"} />
        </div>
      </div>
      <Carriers />
    </div>
  );
};

export default CarriersPage;
