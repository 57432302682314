import { makeAutoObservable } from "mobx";
import axiosInstance from '../api/axios';
import { toast } from 'react-toastify';

interface InquiryData {
    name: string;
    email: string;
    businessName: string;
    phone?: string;
    message?: string;
}

interface FormSubmissionData {
    form_name: string;
    data: Record<string, any>;
}

class FormStore {
  loading: boolean = false;
  submissionStatus: 'success' | 'error' | null = null;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async submitForm(data: FormSubmissionData): Promise<void> {
    try {
      this.loading = true;
      const response = await axiosInstance.post('/api/website/submit-form/', data);
      this.submissionStatus = 'success';
      toast.success('Form submitted successfully!');
    } catch (error: any) {
      this.submissionStatus = 'error';
      this.error = error.response ? error.response.data : 'Network error';
      toast.error(`Error submitting form: ${this.error}`);
    } finally {
      this.loading = false;
    }
  }

  resetStore(): void {
    this.submissionStatus = null;
    this.error = null;
    this.loading = false;
  }
}

const formStore = new FormStore();
export default formStore;
