import React from 'react';
import CloudBubble from '../utilities/CloudBubble';
import { ReactComponent as RoundedDivider } from '../../assets/images/Rounded-Divider.svg';
import { ReactComponent as LogoWatermark } from '../../assets/images/Logo-Watermark.svg'
import QuoteForm from '../utilities/QuoteForm';
import { useNavigate } from 'react-router-dom';

const FirstSection: React.FC = () => {
    // Utility component to conditionally render line breaks
    const Br = ({ showOnMobile = false }) => (
        <span className={`${showOnMobile ? '' : 'hidden sm:inline'}`}><br/></span>
    );

    return (
        <div className="relative w-full">
            <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/IS_man_sitting.webp"} alt="Background" className="w-full block" />
    
            {/* Responsive text positioning */}
            <div className="flex flex-col sm:absolute top-0 left-0 w-full p-8 z-10 h-full">
                {/* Top section with text and cloud bubble - stays at the top */}
                <div className="flex flex-col sm:flex-row items-start">
                    <div className="text-center justify-center md:text-left md:flex-grow text-sunset-text-orange font-maleo font-medium tracking-[.1em] text-xl sm:tracking-[.3em] sm:text-3xl">
                        Insuring <Br />
                        YOUR KEY PERSONNEL <Br showOnMobile={true} /> {/* Hide BR on small screens */}
                        Protect your business <Br />
                        and ensure continuity <Br />
                        when unexpected events <Br />
                        occur.
                    </div>
    
                    {/* Cloud bubble positioned responsively */}
                    <div className="flex-none w-full sm:w-1/4 sm:-translate-x-full hidden sm:block">
                        <CloudBubble imageUrl={"https://bay-and-basin-bucket.s3.amazonaws.com/IS_walking.webp"} />
                    </div>
                </div>
    
                {/* Responsive semi-transparent rectangle for detailed explanation */}
                <div className="mt-8 sm:absolute sm:bottom-12 bg-[rgba(60,85,114,.4)] text-white p-4 text-center">
                    <h2 className="font-maleo tracking-[.2em] font-regular text-2xl sm:text-4xl">Why Is Key Man Insurance Important?</h2>
                    <p className="font-maleo tracking-[.2em] font-regular text-sm sm:text-xl mt-4">
                        Key Man Insurance is critical for businesses that heavily rely on one or a few key individuals. This insurance helps cover the financial losses that may occur if the key person were to unexpectedly pass away or become unable to work. It provides the business with financial support during the transition period, allowing operations to continue smoothly without causing significant disruption or financial hardship. Benefits include securing loans, attracting investors, and providing a cash flow buffer to find or train a suitable replacement.
                    </p>
                </div>
            </div>
        </div>
    );
};

const insuranceOptions = [
    "WHOLE LIFE",
    "TERM LIFE",
    "BUY-SELL AGREEMENTS",
    // "BUSINESS CONTINUATION"
];

const slugify = (text: string) =>
    text.toLowerCase()
        .replace(/['’]/g, '') // Remove single quotes
        .replace(/[^a-z0-9]+/g, '-') // Replace spaces and other non-alphanumeric characters with hyphens
        .replace(/^-+|-+$/g, ''); // Trim leading and trailing hyphens

const InsuranceOptions = () => {
    const navigate = useNavigate();

    const handleNavigate = (slug: string) => {
        navigate(`/insurance-services/life-and-more/${slug}`);
    }
    
    return (
        <div className="flex flex-col gap-2 sm:gap-3">
            {insuranceOptions.map((option, index) => {
                const slug = slugify(option);
                return(
                <div key={option} className="flex items-center">
                    {/* Increase the size of the circle icon for better visibility and touch on mobile */}
                    <div className="w-12 h-12 sm:w-10 sm:h-10 bg-white border-4 sm:border-2 border-sunset-text-orange rounded-full z-20"></div>
                    {/* Responsive button sizing and improved padding for touch interactions */}
                    <button onClick={() => handleNavigate(slug)} className={`text-sm sm:text-sm md:text-base lg:text-lg ml-[-24px] sm:ml-[-16px] flex-1 py-3 sm:py-2 px-5 sm:px-4 rounded-full font-maleo font-regular uppercase tracking-wider transition-colors duration-300 
                                        ${index % 4 === 0 ? 'bg-sunset-text-orange text-sea-blue' : 
                                         index % 4 === 1 ? 'bg-wave-blue text-white' : 
                                         index % 4 === 2 ? 'bg-basin-light-brown text-sea-blue' :
                                         'bg-sunrise-yellow text-sea-blue'} 
                                        hover:bg-sea-blue hover:text-white`}>
                        {option}
                    </button>
                </div>)
            })}
        </div>
    );
}

const OrganizedSection: React.FC = () => {
    return (
        <div className="relative w-full overflow-hidden z-10">
            {/* Background Image */}
            <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/sunset_birds.webp"} alt="Decorative Background" className='absolute z-0 bottom-0 w-full h-auto' />

            {/* Watermark Logo */}
            <div className="absolute -left-20 top-0 md:left-0 md:top-10">
                <LogoWatermark className="w-4/5 opacity-75 mx-auto" />
            </div>

            {/* Content Heading */}
            <div className="pt-10 px-5 md:pt-20 md:pr-10 lg:pr-20 text-center">
                <h2 className='font-maleo tracking-[.2em] text-sunset-text-orange text-3xl md:text-4xl'>KEY MAN LIFE INSURANCE</h2>
                <p className='font-maleo tracking-[.2em] p-5 text-sea-blue md:mx-20 text-base md:text-lg'>
                    Key Man Life Insurance protects businesses from the financial impacts of losing an indispensable employee. It's essential for companies where the continuity and operations are tied closely to specific individuals whose loss can pose a significant financial threat to the business's stability.
                </p>
            </div>

            {/* Insurance Options and Quote Form Layout */}
            <div className="flex flex-col md:flex-row justify-between items-start px-5 md:px-20">
                {/* Left Column for Insurance Options */}
                <div className="flex-1 min-w-0 md:w-1/2 z-10 pb-10 md:pb-20">
                    <InsuranceOptions />
                    <h2 className='text-[#D2812C] mt-10 font-maleo tracking-[.3em] text-3xl md:text-4xl'>GET COVERED</h2>
                    <p className='text-sea-blue font-maleo text-lg md:text-xl tracking-[.2em]'>
                        Key Man Life Insurance provides several benefits, including:
                    </p>
                    <p className='mb-10 text-sea-blue font-maleo tracking-[.3em] text-lg md:text-xl'>
                        * Risk mitigation for critical roles<br/>
                        * Business continuity planning<br/>
                        * Financial stability and security<br/>
                        * Facilitation of business capital preservation<br/>
                        * Attraction and retention of top talent with enhanced benefits
                    </p>
                </div>

                {/* Right Column for Quote and Contact Info */}
                <div className="w-full md:w-1/2 space-y-8 md:mt-0 z-30">
                    <div className='w-3/4 mx-auto'>
                        <QuoteForm />
                    </div>
                    <div className='text-center'>
                        <h2 className='text-sea-blue font-maleo tracking-[.2em] text-3xl md:text-4xl'>TALK TO AN EXPERT</h2>
                        <p className='text-sea-blue font-maleo tracking-[.2em] text-base md:text-lg'>
                            Contact us for more details or immediate assistance.
                        </p>
                        <p className='mb-10 text-sea-blue font-maleo tracking-[.2em] text-xl md:text-2xl'>
                            831-540-4583
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}



const LifeAndMore: React.FC = () => {
    return (
        <div style={{ position: 'relative', marginTop: '0%', overflow: 'hidden' }}>
            {/* FirstSection remains unchanged as its responsiveness should be handled within the component itself */}
            <FirstSection />

            {/* Adjusted Rounded Divider for better mobile viewability */}
            <RoundedDivider className="w-full sm:w-4/5 relative left-1/2 bottom-0 z-30 mt-[-10px] sm:mt-[-20px] transform -translate-x-1/2" />

            {/* OrganizedSection should also be responsive; adjust within the component as needed */}
            <OrganizedSection />
        </div>
    );
}

export default LifeAndMore;