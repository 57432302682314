import { makeAutoObservable, runInAction } from "mobx";
import API from '../api/axios';

export interface InsuranceDetails {
    image: string;
    title: string;
    description1: string;
    title2: string;
    description2: string;
    title3: string;
    description3: string;
    title4: string;
    descriptionItems: string[];
}

interface InsuranceDetailsFromAPI {
    image: string;
    title: string;
    description1: string;
    title2: string;
    description2: string;
    title3: string;
    description3: string;
    title4: string;
    description_items: string[];
}

class InsuranceStore {
    insuranceDetails: InsuranceDetails | null = null;
    isLoading: boolean = false;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchInsuranceDetails(option: string): Promise<void> {
        this.isLoading = true;
        this.error = null;
        try {
            const response = API.get<InsuranceDetailsFromAPI>(`/api/website/insurance-options/${option}`);
            const unFormattedData: InsuranceDetailsFromAPI = ((await response).data)
            const data: InsuranceDetails = {...unFormattedData, descriptionItems: unFormattedData.description_items}

            runInAction(() => {
                this.insuranceDetails = data;
                this.isLoading = false;
            });
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message || "Failed to fetch insurance details.";
                this.isLoading = false;
            });
        }
    }

    get insuranceData(): InsuranceDetails | null {
        return this.insuranceDetails;
    }
}

const insuranceStore = new InsuranceStore();
export default insuranceStore;
