import { FC, ReactNode } from "react";
import { ScrollRestoration } from "react-router-dom";
import Header from "./Header";

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/ToastStyles.css'; // Import your custom styles
import Footer from "./Footer";

interface RootProps {
  children?: ReactNode
}

const Root: FC<RootProps> = ({children}) => {
    return (
        <div className="site-container">
            <Header />
            <div className="main-content overflow-x-hidden overflow-y-auto">
                {children}
            </div>
            <Footer />
            <ToastContainer position="top-center" autoClose={2500} />
            <ScrollRestoration getKey={(location) => {
                        return location.pathname;
                    }} /> 
        </div>
    );
}

export default Root;
