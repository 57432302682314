import React from 'react';
import { Outlet } from 'react-router-dom';
import InsuranceServicesHeader from './CommonHeader';
import { ReactComponent as ColorfulDivider } from '../../assets/images/Colorful-Divider.svg';

const InsuranceServices: React.FC = () => {
    return(
        <div>
            {/* Lovely things here (below this comment)! For your index.*/}
            <InsuranceServicesHeader />
            <ColorfulDivider className="w-full h-120vh mt-[-17px] z-50" />
            {/* The Outlet component will render the matched child route here */}
            <Outlet />
        </div>
    )
}

export default InsuranceServices;