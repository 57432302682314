import React from "react";


const JobDetails: React.FC = () => {
    return (
        <div className="text-base">
            <div>
                <span className="font-regular text-basin-light-brown">Department:</span>
                <span className="font-light text-sea-blue ml-2">Commercial Lines</span>
            </div>
            
            <div>
                <span className="font-regular text-basin-light-brown">Reports To:</span>
                <span className="font-light text-sea-blue ml-2">Lead Customer Service Representative</span>
            </div>
            
            <div>
                <span className="font-regular text-basin-light-brown">Status:</span>
                <span className="font-light text-sea-blue ml-2">Non-Exempt/Full Time</span>
            </div>
            
            <div>
                <span className="font-regular text-basin-light-brown">Salary Range:</span>
                <span className="font-light text-sea-blue ml-2">$20-30 per hour DOE</span>
            </div>
        </div>
    );
};


const CommercialLinesAccountManager: React.FC = () => {
    return(
        <div className="p-8">
        <h1 className="text-center text-3xl font-bold text-basin-brown">JOB OFFERS</h1>
        <h2 className="text-left text-2xl font-semibold text-sea-blue mt-6 mb-4">COMMERCIAL LINES ACCOUNT MANAGER</h2>
        
        <JobDetails/>
        <div className="text-base">
            <div className="font-regular text-basin-light-brown mb-1"><br/>Position Summary:</div>
            <p className="font-light text-sea-blue">
                To serve and grow the book of clients assigned to you by providing extraordinary service, educating the customer,
                identifying revenue growth opportunities and generating referrals. Account managers focus on growth within their current
                book of business while also backing up their co-workers.
            </p>
            
            <div className="font-regular text-basin-light-brown mt-4 mb-1">Job Functions:</div>
            <ul className="list-disc pl-5 font-light text-sea-blue">
                <li>Establish working relationships with clients (and their key team members), potential clients and companies we represent on the phone, email, and on paper.</li>
                <li>Supporting teammates with follow-up for clients and prospects.</li>
                <li>Research and resolve coverage questions.</li>
                <li>Promptly respond to client needs either on the phone or electronically.</li>
                <li>Maintain client files and process routine correspondence between our agency and the client or company.</li>
                <li>Review and prioritize all insureds' requests for service and to submit information to insurance companies within 24 hours of receipt.</li>
                <li>Process all monied transactions the day they are received.</li>
                <li>Review and order all necessary renewals no later than 90 days prior to expiration.</li>
                <li>Account remarketing.</li>
                <li>Perform miscellaneous input functions.</li>
                <li>Electronically or via the internet - transmit change requests, cancellation requests, etc. to and from company.</li>
            </ul>
            
            <div className="font-regular text-basin-light-brown mt-4 mb-1">Additional Duties:</div>
            <ul className="list-disc pl-5 font-light text-sea-blue">
                <li>Generating referrals for other departments.</li>
                <li>Educating the clients about the benefits of having Bay and Basin Insurance as their agency.</li>
                <li>Generating additional revenue on the book of business in the form of increased coverage, account rounding, adding policy features, and generating referrals for new business.</li>
                <li>Stop, Listen, and Ask questions of your customers to clearly understand their needs and to build solid relationships.</li>
                <li>Sending out renewal surveys to clients in an efficient manner.</li>
                <li>Provide outstanding inbound service on claims, billing questions, and endorsements.</li>
                <li>Work to manage clients' expectations.</li>
                <li>Follow up via phone or email with clients.</li>
                <li>Use every opportunity to confirm and update client information.</li>
                <li>Work to increase and improve efficiency on a daily basis. This includes always finding ways to streamline processes, use technology tools to the fullest, and limiting general distractions.</li>
                <li>Possess and maintain a positive mental attitude, including positive working relationships with clients and agency personnel.</li>
                <li>Work to support the marketing and branding team in the following ways:</li>
                <li>Suggest blog, email, and social media topics to the marketing department.</li>
                <li>Share agency social media posts on personal social networks.</li>
                <li>Participate in pictures and agency branding opportunities when invited.</li>
            </ul>

            <div className="font-regular text-basin-light-brown mt-4 mb-1">Requirements & Skills:</div>
            <ul className="list-disc pl-5 font-light text-sea-blue">
                <li>Proficient with Microsoft Word, Excel, Outlook, and a calculator.</li>
                <li>Type quickly and accurately.</li>
                <li>Excellent communication skills both verbal and written.</li>
                <li>Organized, structured, punctual, and will follow the rules.</li>
                <li>Patient, respectful, reliable, trustworthy, friendly, supportive, articulate, kind, ethical, and honest.</li>
            </ul>

            <div className="font-regular text-basin-light-brown mt-4 mb-1">Education:</div>
            <p className="font-light text-sea-blue">
                High school diploma
            </p>

            <div className="font-regular text-basin-light-brown mt-4 mb-1">Experience:</div>
            <p className="font-light text-sea-blue">
                No previous insurance experience is required. We will train and educate the successful candidate. However, having previous experience working within the insurance industry is a bonus.
            </p>

            <h3 className="font-regular text-basin-light-brown mt-4 mb-2">Limitations and Disclaimer:</h3>
            <p className="font-light text-sea-blue">
                All job requirements are subject to possible modifications to reasonably accommodate individuals with disabilities. Some requirements may exclude individuals who pose a direct threat or significate risk to the health and safety of themselves or other employees.
            </p>
            <p className="font-light text-sea-blue mt-2">
                This job description in no way states or implies that these are the only duties to be performed by the employee occupying this position. Employees will be required to follow any other job-related instructions and to perform other job-related duties requested by their supervisor in compliance with Federal and State Laws.
            </p>
            <p className="font-light text-sea-blue mt-2">
                Requirements are representative of minimum level of knowledge, skills and/or abilities. To perform this job successfully, the employee must possess the abilities or aptitudes to perform each duty proficiently. Continued employment remains on an “at-will” basis.
            </p>
        </div>
    </div>
    );
};

export default CommercialLinesAccountManager;
