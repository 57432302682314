import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import blogStore from '../../stores/BlogStore';

const BlogPostPage: React.FC = observer(() => {
  const { option } = useParams<{ option: string }>();

  if(!option) return (<></>);

  useEffect(() => {
    blogStore.fetchBlogPost(option);
  }, [option]);

  if (!blogStore.blogPost) {
    return <div>Loading...</div>;
  }

  const { title, image_url, sections } = blogStore.blogPost;

  return (
    <article>
      <div className="relative">
        <img src={image_url} alt={title} className="w-auto h-.5vh" />
        <div className="absolute bottom-0 w-full bg-basin-light-brown p-6">
          <h1 className="text-white font-maleo text-4xl font-bold">{title}</h1>
        </div>
      </div>

      <div className="p-6">
        {sections.map((section, index) => (
          <section key={index}>
            {section.header && <h2 className="text-sunset-light-orange font-bold text-lg">{section.header}</h2>}
            <p className="text-sea-blue">{section.content}</p>
          </section>
        ))}
      </div>
    </article>
  );
});

export default BlogPostPage;
