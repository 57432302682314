import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ColorfulDivider } from '../../assets/images/Colorful-Divider.svg';
import QuoteForm from '../utilities/QuoteForm';
import SideCloudBubble from '../utilities/SideCloudBubble';

interface DetailedInsuranceProps {
    image: string;
    title: string;
    description1: string;
    title2: string;
    description2: string;
    title3: string;
    description3: string;
    title4: string;
    descriptionItems: string[];
}

const DetailedInsurance: React.FC<DetailedInsuranceProps> = ({
    image, title, description1, title2, description2, title3, description3, title4, descriptionItems
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const pageOrigin = location.pathname.includes('business-insurance') ? 'Business Insurance' : 'Life and More';
    const backgroundColor = pageOrigin === 'Business Insurance' ? 'bg-sea-blue rounded-full text-white font-maleo w-2/3 tracking-double pl-4 text-left' : 'pl-4 text-left bg-sunset-text-orange w-2/3 tracking-double font-maleo rounded-full';
    const buttonText = pageOrigin === 'Business Insurance' ? 'Go to Business Insurance' : 'Go to Life Insurance';

    const handleBack = () => {
        if (pageOrigin === 'Business Insurance') {
            navigate('/insurance-services/business-insurance');
        } else {
            navigate('/insurance-services/life-and-more');
        }
    };

    return (
        <div>
            <div className="detailed-insurance-page relative flex flex-col md:flex-row md:items-stretch h-screen">
                {/* Column 1 */}
                <div className="column1 flex flex-col py-10 w-full md:w-1/2 mx-auto text-center md:text-left md:pl-10">
                    <button onClick={handleBack} className={`${backgroundColor} my-4 mx-auto md:mx-0`}>
                        {buttonText}
                    </button>
                    <div className='justify-center items-center'>
                        <h1 className='mt-4 py-4 text-sunset-text-orange font-maleo tracking-double text-4xl'>{title}</h1>
                        <p className="my-2 text-wave-blue font-maleo tracking-double text-4xl font-light">{description1}</p>
                    </div>
                    <ColorfulDivider className="mx-auto w-1/2" />
                    <div className='flex-col justify-center items-center'>
                        <h2 className="my-8 text-sunset-text-orange font-maleo font-regular text-3xl tracking-double">{title2}</h2>
                        <p className="my-8 font-maleo text-sea-blue font-regular tracking-double text-xl">{description2}</p>
                        <h2 className="text-5xl font-maleo text-center text-sunset-text-orange tracking-double">{title3}</h2>
                        <p className="font-maleo text-sea-blue font-regular tracking-double text-xl">{description3}</p>
                    </div>
                    <div className="text-center mt-8">
                        <p className="text-2xl font-maleo font-regular text-sunset-text-orange tracking-double">TALK TO AN AGENT</p>
                        <p className="text-2xl font-regular font-maleo text-sunset-text-orange tracking-double">Or call for immediate assistance.</p>
                        <p className="text-4xl font-maleo text-sea-blue tracking-double">831-540-4583</p>
                    </div>
                </div>
                {/* Column 2 */}
                <div className="column2 flex flex-col w-full md:w-1/2" >
                    <div className='flex justify-center items-center' style={{ height: '50%' }}> 
                        {/* Centering the bubble and restricting its size with inline styles */}
                        <div className='w-1/2'>
                            <SideCloudBubble imageUrl={image} clipPath="sideCloudBubble-clip" translateX={200} />
                        </div>
                    </div>
                    <div className="content font-maleo py-10 px-4 text-center tracking-double" style={{ height: '50%' }}>
                        <h3 className='text-basin-brown text-xl'>{title4}</h3>
                        <ul className="list-none mx-auto my-4">
                            {descriptionItems.map((item, index) => (
                                <li className="text-sea-blue text-xl my-2" key={index}>{item}</li>
                            ))}
                        </ul>
                        <div className="mt-4">
                            <QuoteForm />
                        </div>
                    </div>
                </div>
            </div>
            <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/Sun.webp"} alt="Background" style={{ width: '100%', display: 'block' }} />
        </div>
    );
    
    
};

export default DetailedInsurance;
