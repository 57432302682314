import React from 'react';
import ImagePairWithOverlay from '../utilities/ImagePairWithOverlay';
import { ImageInfo } from '../utilities/ImageWithOverlay';
import { ReactComponent as WeCoverLeftSVG } from '../../assets/images/homepage/WeCover-Left.svg';
import { ReactComponent as WeCoverRightSVG } from '../../assets/images/homepage/WeCover-Right.svg';
import { useNavigate } from 'react-router-dom';
import CloudBubble from '../utilities/CloudBubble';

export const TextSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-start mr-2 ml-8 lg:mr-8 max-w-full lg:max-w-3/5">
      <h1 className="text-xl md:text-3xl lg:text-[3.8vw] font-maleo tracking-maleo font-light text-sea-blue mb-2 md:mb-4 lg:mb-6" style={{ minWidth: '250px' }}>
        Business Insurance for
      </h1>
      <h2 className="text-lg md:text-2xl lg:text-[3.3vw] font-maleo font-bold mt-2 mb-4 md:mb-6 lg:mb-8 text-sea-blue tracking-wider" style={{ minWidth: '220px', lineHeight: '1.4', whiteSpace: 'nowrap' }}>
        WORRY-FREE DAYS
      </h2>
      <div className="self-end mt-2">
        <button 
          onClick={() => navigate('/contact-us')} 
          className="bg-sea-blue text-white rounded-full font-light cursor-pointer hover:bg-blue-800 text-base md:text-lg lg:text-[1.5vw] px-6 md:px-8 lg:px-[1.5vw] py-2 md:py-3"
        >
          GET A BUSINESS INSURANCE QUOTE
        </button>
      </div>
    </div>
  );
};


interface HighFiveComponentProps {
    highFiveImage: string;
  }
  
export const HighFiveComponent: React.FC<HighFiveComponentProps> = ({ highFiveImage }) => (
  // <img src={"https://bay-and-basin-bucket.s3.amazonaws.com/coworkers.webp"} alt='' className='w-1/2 h-auto'/>
    <CloudBubble imageUrl={"https://bay-and-basin-bucket.s3.amazonaws.com/coworkers.webp"}/>
    // <HomepageBeauty3 className='w-1/2 h-auto'/>
  );


  export const WeCoverWhatMattersHeader: React.FC = () => {
    return (
      <div className="flex flex-col md:flex-row justify-center items-center w-full px-4 mb-16">
        <div className="w-full md:flex-1 md:min-w-[20%] md:max-w-[25%] overflow-visible mb-4 md:mb-0">
          <WeCoverLeftSVG className="mx-auto md:relative md:left-0 md:translate-x-[-30%] md:translate-y-[-8%]" />
        </div>
        <div className="flex flex-col items-center space-y-4 px-4 flex-auto">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-regular tracking-tight md:tracking-[.25em] text-sunset-light-orange mb-4">
            WE COVER WHAT MATTERS<br /> TO YOUR BUSINESS
          </h1>
          <p className="text-sm md:text-md lg:text-lg text-sea-blue text-center">
            As an Independent Insurance Agency, we do the difficult work of finding your business the most comprehensive coverage, with a competitive price while offering personalized service.
          </p>
        </div>
        <div className="w-full md:flex-1 md:min-w-[20%] md:max-w-[55%] overflow-visible">
          <WeCoverRightSVG className="mx-auto md:relative md:right-0 md:translate-x-[25%]" />
        </div>
      </div>
    );
};

  interface WeCoverWhatMattersImagesProps {
    image1: ImageInfo;
    image2: ImageInfo;
  }
  
  export const WeCoverWhatMattersImages: React.FC<WeCoverWhatMattersImagesProps> = ({ image1, image2 }) => {
    return (
      <div className="flex justify-center px-4 sm:px-6 md:px-8 lg:px-16"> {/* Gradual padding increase */}
        <div className="w-full max-w-screen-xl"> {/* Limit maximum width */}
          <ImagePairWithOverlay
            image1={image1}
            image2={image2}
            showToggles={true} // Assuming this controls some UI feature like visibility toggles
          />
        </div>
      </div>
    );
};
  

  export const WeCoverWhatMatters: React.FC = () => {
    const businessImage: ImageInfo = {
        src: "https://bay-and-basin-bucket.s3.amazonaws.com/business-insurance.webp",
        overlayColor: "#799daa",
        opacity: 0.8,
        text: "Cover your business with employee benefits, liability coverage, and everything else you need to keep it running smoothly.",
        textPosition: 'center',
        href: '/insurance-services/business-insurance',
        isVisible: true
    };

    const lifeImage: ImageInfo = {
        src: "https://bay-and-basin-bucket.s3.amazonaws.com/Homepage-Life.webp",
        overlayColor: "#FBAC46",
        opacity: 0.8,
        text: "Protect your key personnel with our comprehensive key man insurance options.",
        textPosition: 'center',
        href: '/insurance-services/life-and-more',
        isVisible: true
    };

    return (
        <div className="bg-white z-10 mt-16 text-center px-4 sm:px-6 relative"> {/* Responsive padding */}
            <WeCoverWhatMattersHeader />
            <WeCoverWhatMattersImages image1={businessImage} image2={lifeImage} />
        </div>
    );
};  
  
export const BigSurSection: React.FC = () => {
  const navigate = useNavigate(); // Hook to control navigation

  const handleReadMoreClick = () => {
    navigate('/blog'); // Navigate to the blog page
  };

  return (
      <div className="relative text-white bg-cover bg-center bg-no-repeat" 
           style={{ 
               backgroundImage: `url("https://bay-and-basin-bucket.s3.amazonaws.com/Waves.webp")`,
               minHeight: '25vh' // Ensure that there's a minimum height for smaller devices
           }}>
        <div className="flex flex-col items-center justify-center py-8 sm:py-12 md:py-24 px-4 sm:px-8 md:px-16 text-center">
          <p className="text-md sm:text-xl md:text-2xl lg:text-3xl font-light mb-6 max-w-4xl mx-auto">
            OUR INSURANCE PROFESSIONALS UNDERSTAND THE INS AND OUTS OF INSURING A BUSINESS AND STAYING ATTUNED TO THE MARKETPLACE AND WHAT UNDERWRITERS ARE OFFERING IN TERMS OF NEW COVERAGE LINES, SAFETY AND RISK MANAGEMENT AND MORE. AND WHEN YOUR BUSINESS SUFFERS A LOSS, WE’LL BE THERE OFFERING EXCELLENT CLAIMS’ SERVICE FROM START TO FINISH. WE ENCOURAGE YOU TO EXPLORE ALL OF THIS IN OUR BLOG!
          </p>
          <button 
              onClick={handleReadMoreClick} 
              className="bg-basin-light-brown text-sea-blue text-xs sm:text-sm py-2 px-8 sm:px-16 rounded-full" // Responsive text sizing and padding
              >
            READ MORE
          </button>
        </div>
      </div>
    );
};

