import React from "react";

const JobDetailsSalesRep: React.FC = () => {
    return (
        <div className="text-base">
            <div>
                <span className="font-regular text-basin-light-brown">Position Title:</span>
                <span className="font-light text-sea-blue ml-2">Commercial Lines Sales Representative</span>
            </div>

            <div>
                <span className="font-regular text-basin-light-brown">Department:</span>
                <span className="font-light text-sea-blue ml-2">Commercial Lines</span>
            </div>

            <div>
                <span className="font-regular text-basin-light-brown">Reports To:</span>
                <span className="font-light text-sea-blue ml-2">Owner</span>
            </div>

            <div>
                <span className="font-regular text-basin-light-brown">Status:</span>
                <span className="font-light text-sea-blue ml-2">Non-Exempt/Full Time</span>
            </div>
        </div>
    );
};

const CommercialLinesSalesRep: React.FC = () => {
    return(
        <div className="p-8">
            <h1 className="text-center text-3xl font-bold text-basin-brown">JOB OFFERS</h1>
            <h2 className="text-left text-2xl font-semibold text-sea-blue mt-6 mb-4">COMMERCIAL LINES SALES REPRESENTATIVE</h2>
            
            <JobDetailsSalesRep/>

            <div className="text-base">
                <div className="font-regular text-basin-light-brown mb-1"><br/>Position Summary:</div>
                <p className="font-light text-sea-blue">
                    You are a capable salesperson and enjoy the sales process. You are comfortable cold calling either in person, over the phone, and by meeting people in person. You desire to be part of a team and want to be part of the solution. You also desire to serve and grow the book of clients assigned to you by providing extraordinary service, educating the customer, identifying revenue growth opportunities, and generating referrals.
                </p>

                <div className="font-regular text-basin-light-brown mt-4 mb-1">Job Functions:</div>
                <ul className="list-disc pl-5 font-light text-sea-blue">
                    <li>Cultivate new opportunities and offer additional lines of insurance through consultative selling.</li>
                    <li>Strong written and verbal communication skills.</li>
                    <li>Drafting prospect proposals and/or presentations.</li>
                    <li>Sales forecasting.</li>
                    <li>Promptly respond to client needs either on the phone or electronically.</li>
                    <li>Establish new relationships with potential clients and sign them up for insurance.</li>
                    <li>Cold calling over the phone or electronically connecting with people to sell insurance.</li>
                    <li>Once a product is sold, you will need to fill out appropriate forms and input into the software system.</li>
                    <li>Cultivate relationships and cross-sell additional lines of insurance.</li>
                    <li>Ability to input Acord applications, draft invoices & collect payments.</li>
                    <li>Research and resolve coverage questions.</li>
                    <li>Perform miscellaneous functions.</li>
                </ul>

                <div className="font-regular text-basin-light-brown mt-4 mb-1">Additional Duties:</div>
                <ul className="list-disc pl-5 font-light text-sea-blue">
                    <li>Generating additional revenue on the book of business in the form of increased coverage, account rounding, adding policy features, and generating referrals for new business.</li>
                    <li>Suggesting and selling other insurance products.</li>
                    <li>Asking for referrals.</li>
                    <li>Stop, Listen, and Ask questions of your customers to clearly understand their needs and to build solid relationships.</li>
                    <li>Provide outstanding inbound service on all client and prospect interactions.</li>
                    <li>Work to manage clients' expectations.</li>
                    <li>Follow up via phone or email with clients.</li>
                    <li>Use every opportunity to confirm and update client information.</li>
                    <li>Educate clients on the benefits of using our agency automation.</li>
                    <li>Work to increase and improve efficiency on a daily basis.</li>
                    <li>Possess and maintain a positive mental attitude, including positive working relationships with clients and agency personnel.</li>
                    <li>Work to support the marketing and branding team in the following ways:</li>
                    <li>Provide them any information to be entered into marketing campaigns.</li>
                    <li>Suggest blog, email, and social media topics to the marketing department.</li>
                    <li>Share agency social media posts on personal social networks.</li>
                    <li>Participate in pictures and agency branding opportunities when invited.</li>
                </ul>

                <div className="font-regular text-basin-light-brown mt-4 mb-1">Requirements & Skills:</div>
                <ul className="list-disc pl-5 font-light text-sea-blue">
                    <li>Proficient with Microsoft Word, Excel, Outlook, and a calculator.</li>
                    <li>Type quickly and accurately.</li>
                    <li>Excellent communication skills both verbal and written.</li>
                    <li>Organized, structured, punctual, and will follow the rules.</li>
                    <li>Patient, respectful, reliable, trustworthy, friendly, supportive, articulate, kind, ethical, and honest.</li>
                </ul>

                <div className="font-regular text-basin-light-brown mt-4 mb-1">Limitations and Disclaimer:</div>
                <p className="font-light text-sea-blue">
                    All job requirements are subject to possible modifications to reasonably accommodate individuals with disabilities. Some requirements may exclude individuals who pose a direct threat or significate risk to the health and safety of themselves or other employees.
                </p>
                <p className="font-light text-sea-blue mt-2">
                    This job description in no way states or implies that these are the only duties to be performed by the employee occupying this position. Employees will be required to follow any other job-related instructions and to perform other job-related duties requested by their supervisor in compliance with Federal and State Laws.
                </p>
                <p className="font-light text-sea-blue mt-2">
                    Requirements are representative of the minimum level of knowledge, skills, and/or abilities. To perform this job successfully, the employee must possess the abilities or aptitudes to perform each duty proficiently. Continued employment remains on an “at-will” basis.
                </p>
            </div>
        </div>
    );
};

export default CommercialLinesSalesRep;
