import React, { useRef } from "react";
import { PaymentInfoSection } from "../utilities/PaymentInfo";
import CircleBubble from "../utilities/CircleBubble";
import { ReactComponent as ThickColorfulDivider } from '../../assets/images/Thick-Colorful-Divider.svg';
import { ReactComponent as DollarSign } from '../../assets/images/clientPortal/DollarSign.svg';
import { ReactComponent as ReportClaim } from '../../assets/images/clientPortal/ReportClaim.svg';
import { ReactComponent as UpdateContact } from '../../assets/images/clientPortal/UpdateContact.svg';
import { ReactComponent as PolicyChange } from '../../assets/images/clientPortal/PolicyChange.svg';
import GenericForm, { FieldConfig } from "../utilities/GenericForm";
import {ReactComponent as LogoWatermark} from '../../assets/images/Logo-Watermark.svg'
import formStore from "../../stores/FormsStore";


const ClientPortal: React.FC = () => {
    // Creating refs for each section
    const paymentInfoRef = useRef<HTMLDivElement>(null);
    const reportClaimRef = useRef<HTMLDivElement>(null);
    const updateContactRef = useRef<HTMLDivElement>(null);
    const policyChangeRef = useRef<HTMLDivElement>(null);

    // Scroll to ref function
    const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="bg-white relative">
            <LogoWatermark className="absolute left-0 top-1/2 -translate-x-1/4 w-80 opacity-20 md:opacity-100" />
            <LogoWatermark className="absolute right-0 bottom-1/4 translate-x-1/4 w-80 opacity-20 md:opacity-100" />
            <LogoWatermark className="absolute left-0 bottom-10 -translate-x-1/4 w-80 opacity-20 md:opacity-100" />

            <div className="flex flex-col md:flex-row items-center py-8">
                <h1 className="text-3xl md:text-5xl text-center text-sea-blue flex-1">
                    CLIENT<br/>PORTAL
                </h1>
                <div className="flex flex-1 justify-center items-center">
                    <CircleBubble imageUrl={"https://bay-and-basin-bucket.s3.amazonaws.com/HighFive.webp"} clipPath="high-five-circle-bubble"/>
                </div>
            </div>

            <div className="relative">
                <ThickColorfulDivider />
                <div className="flex flex-wrap justify-center items-start pb-10 mt-[-1px]">
                    {[
                        { ref: paymentInfoRef, Icon: DollarSign, text: "PAYMENT" },
                        { ref: reportClaimRef, Icon: ReportClaim, text: "REPORT A CLAIM" },
                        { ref: updateContactRef, Icon: UpdateContact, text: "UPDATE CONTACT INFORMATION" },
                        { ref: policyChangeRef, Icon: PolicyChange, text: "POLICY CHANGE" }
                    ].map(({ ref, Icon, text }, index) => (
                        <div key={index} className="flex flex-col items-center p-8 bg-sea-blue rounded-b-lg shadow-lg mx-4 my-2 md:my-0 cursor-pointer" onClick={() => scrollToRef(ref)}>
                            <Icon className="h-12 w-12 text-white"/>
                            <span className="text-white mt-2 text-base font-bold">{text}</span>
                        </div>
                    ))}
                </div>

                <div ref={paymentInfoRef}>
                    <PaymentInfoSection/>
                </div>
                <div ref={reportClaimRef} className="flex flex-col items-center justify-center w-full px-4">
                    <GenericForm
                        title="REPORT A CLAIM"
                        fields={reportClaimFields}
                        submitButtonText="UPDATE" 
                        className="w-full max-w-lg mt-16"
                        onSubmit={formData => formStore.submitForm({
                            form_name: "Report of a Claim",
                            data: formData
                        })}
                    />
                </div>
                <div ref={updateContactRef} className="flex flex-col items-center justify-center w-full px-4">
                    <GenericForm
                        title="UPDATE CONTACT INFORMATION"
                        fields={updateContactFields}
                        submitButtonText="UPDATE" 
                        className="w-full max-w-lg mt-16"
                        onSubmit={formData => formStore.submitForm({
                            form_name: "Update Contact Information Request",
                            data: formData
                        })}
                    />
                </div>
                <div ref={policyChangeRef} className="flex flex-col items-center justify-center w-full px-4">
                    <GenericForm
                        title="POLICY CHANGE"
                        fields={policyChangeFields}
                        submitButtonText="UPDATE"
                        className="w-full max-w-lg mt-16"
                        onSubmit={formData => formStore.submitForm({
                            form_name: "Policy Change Request",
                            data: formData
                        })}
                    />
                </div>
                <GenericForm
                        title="PROOF OF INSURANCE"
                        fields={proofOfInsuranceFields}
                        submitButtonText="CONTACT US"
                        className="w-full max-w-lg mt-16 mb-16"
                        onSubmit={formData => formStore.submitForm({
                            form_name: "Proof of Insurance Request",
                            data: formData
                        })}
                    />
                </div>
            </div>
    );
};

const reportClaimFields: FieldConfig[] = [
    { key: 'name', type: 'text', placeholder: 'Name', label: 'Name' },
    { key: 'email', type: 'email', placeholder: 'Email', label: 'Email' },
    { key: 'phone', type: 'tel', placeholder: 'Phone', label: 'Phone' },
    { key: 'businessName', type: 'text', placeholder: 'Business Name', label: 'Business Name' },
    {
        key: 'insuranceType',
        type: 'select',
        label: 'Insurance type:',
        options: ['Business Insurance', 'Life Insurance']
    },
    { key: 'message', type: 'textarea', placeholder: 'Message', label: 'Message', rows: 5 }
];


const updateContactFields: FieldConfig[] = [
    { key: 'name', type: 'text', placeholder: 'Name', label: 'Name' },
    { key: 'businessName', type: 'text', placeholder: 'Business Name', label: 'Business Name' },
    {
        key: 'whatChanged',
        type: 'radio',
        label: 'What Changed?',
        options: ['Name', 'Address', 'Phone', 'Email', 'Other']
    },
    { key: 'reasonForChange', type: 'text', placeholder: 'Reason for changing', label: 'Reason for changing' },
    { key: 'contactEmail', type: 'email', placeholder: 'Contact email address', label: 'Contact email address' },
    { key: 'contactPhone', type: 'tel', placeholder: 'Contact phone number', label: 'Contact phone number' },
    { key: 'message', type: 'textarea', placeholder: 'Message', label: 'Message', rows: 5 }
];

const policyChangeFields: FieldConfig[] = [
    { key: 'name', type: 'text', placeholder: 'Name', label: 'Name' },
    { key: 'businessName', type: 'text', placeholder: 'Business Name', label: 'Business Name' },
    { key: 'insuranceCarrier', type: 'text', placeholder: 'Insurance Carrier', label: 'Insurance Carrier' },
    { key: 'policyNumber', type: 'text', placeholder: 'Policy Number', label: 'Policy Number' },
    { key: 'changeRequest', type: 'text', placeholder: 'What would you like to change?', label: 'What would you like to change?' },
    { key: 'email', type: 'email', placeholder: 'Email', label: 'Email' },
    { key: 'phone', type: 'tel', placeholder: 'Phone', label: 'Phone' },
    { key: 'message', type: 'textarea', placeholder: 'Message', label: 'Message', rows: 5 }
];

const proofOfInsuranceFields: FieldConfig[] = [
    { key: 'name', type: 'text', placeholder: 'Name', label: 'Name' },
    { key: 'businessName', type: 'text', placeholder: 'Business Name', label: 'Business Name' },
    {
        key: 'proofType',
        type: 'radio',
        label: 'Type of proof required',
        options: ['Id card', 'Certificate of insurance', 'Declaration pages', 'Other']
    },
    { key: 'insuranceCarrier', type: 'text', placeholder: 'Insurance carrier', label: 'Insurance carrier' },
    { key: 'policyNumber', type: 'text', placeholder: 'Policy number', label: 'Policy number' },
    { key: 'description', type: 'text', placeholder: 'Describe what you need from us', label: 'Describe what you need from us' },
    {
        key: 'deliveryMethod',
        type: 'radio',
        label: 'How do you want it delivered?',
        options: ['Fax', 'Email', 'Mail', 'Pickup in Agency', 'Other']
    },
    { key: 'email', type: 'email', placeholder: 'Email', label: 'Email' },
    { key: 'phone', type: 'tel', placeholder: 'Phone', label: 'Phone' }
];



export default ClientPortal;
