import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">Privacy Policy</h1>
      
      <h2 className="text-xl font-semibold">1. Introduction</h2>
      <p className="text-md text-gray-700 mt-2">
        Your privacy is important to us. It is Bay and Basin Insurance's policy to respect your privacy regarding any information we may collect from you across our website, bayandbasinins.com, and other sites we own and operate.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">2. Information We Collect</h2>
      <p className="text-md text-gray-700 mt-2">
        We collect information directly from you when you register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form. Additionally, we automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page(s) you visited.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">3. Use of Information</h2>
      <p className="text-md text-gray-700 mt-2">
        Any of the information we collect from you may be used in one of the following ways:
        <ul>
          <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
          <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</li>
          <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
          <li>To process transactions</li>
          <li>To administer a contest, promotion, survey or other site feature</li>
          <li>To send periodic emails</li>
        </ul>
      </p>
      
      <h2 className="text-xl font-semibold mt-4">4. Data Protection</h2>
      <p className="text-md text-gray-700 mt-2">
        We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. These include the use of SSL technology and comprehensive data management policies.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">5. Sharing of Information</h2>
      <p className="text-md text-gray-700 mt-2">
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
      </p>

      <h2 className="text-xl font-semibold mt-4">6. Third Party Links</h2>
      <p className="text-md text-gray-700 mt-2">
        Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.
      </p>

      <h2 className="text-xl font-semibold mt-4">7. Consent</h2>
      <p className="text-md text-gray-700 mt-2">
        By using our site, you consent to our privacy policy.
      </p>

      <h2 className="text-xl font-semibold mt-4">8. Changes to our Privacy Policy</h2>
      <p className="text-md text-gray-700 mt-2">
        If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.
      </p>

      <p className="text-md text-gray-700 mt-4">
        This policy was last modified on April 30th, 2024.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
