import React, { useEffect } from 'react';
import SideCloudBubble from '../utilities/SideCloudBubble';
import CircleBubble from '../utilities/CircleBubble';
import { useNavigate } from 'react-router-dom';
import blogStore from '../../stores/BlogStore';
import { observer } from 'mobx-react-lite';

// Define the props type
interface BlogSectionProps {
    title: string;
    content: string;
    imageUrl: string;
    readMoreLink: string;
    bubbleComponent: React.ElementType<{ imageUrl: string, translateX?: number, translateY?: number, clipPath: string }>;
    titleClass?: string;
    contentClass?: string;
    buttonClass?: string;
    brown?: boolean;
    translateX?: number;
    translateY?: number;
    clipPath: string;
  }

  interface BubbleProps {
    imageUrl: string;
    clipPath: string;
    translateX?: number;
    translateY?: number;
  }
  
  const BlogSection: React.FC<BlogSectionProps> = ({
    title,
    content,
    imageUrl,
    readMoreLink,
    bubbleComponent: BubbleComponent,
    titleClass = '',
    contentClass = '',
    buttonClass = '',
    brown = false,
    translateX,
    translateY,
    clipPath
  }) => {
    const isMirrored = BubbleComponent === CircleBubble;
    const navigate = useNavigate();
    
    const textSection = (
      <div className={`md:w-1/2 space-y-4  pl-4 ${isMirrored ? 'order-2' : 'order-1'}`}>
        <h2 className={`text-3xl font-bold font-maleo text-sunset-light-orange mb-4 ${titleClass}`}>{title}</h2>            
        <p className={`text-sea-blue ${contentClass}`}>{content}</p>
        <div className="text-center">
          <button
            onClick={() => navigate(readMoreLink)}
            className={`px-6 py-2 rounded-full transition-colors duration-300 bg-basin-light-brown text-white hover:bg-basin-light-brown-dark ${buttonClass}`}
            >
            Read more
          </button>
        </div>
      </div>
    );
  
    const bubbleProps: BubbleProps = {
        imageUrl: imageUrl,
        clipPath: clipPath,
        ...(translateX !== undefined && { translateX: translateX }),
        ...(translateY !== undefined && { translateY: translateY }),
    };

    const bubbleSection = (
      <div className={`md:w-1/2 ${isMirrored ? 'order-1' : 'order-2'}`}>
        <BubbleComponent {...bubbleProps}/>
      </div>
    );
  
    return (
      <div className="flex flex-col md:flex-row items-center justify-between px-8 py-4">
        {isMirrored ? bubbleSection : textSection}
        {isMirrored ? textSection : bubbleSection}
      </div>
    );
  };

  const Blog: React.FC = observer(() => {

    useEffect(() => {
      blogStore.fetchBlogPosts();
    }, []);

    return (
        <div>
            {blogStore.blogPosts.map((post, index) => (
                <BlogSection
                    key={post.slug}
                    title={post.title}
                    content={post.sections.length > 0 ? `${post.sections[0].content.substring(0, 100)}...` : "No content available."}
                    imageUrl={post.image_url}
                    readMoreLink={post.read_more_link}
                    bubbleComponent={index % 2 === 0 ? SideCloudBubble :  CircleBubble}
                    translateX={post.translate_x}
                    translateY={post.translate_y}
                    clipPath={`${post.slug}-clip`} // Assuming you create a unique clipPath for each post
                />
            ))}
        </div>
    );
});

export default Blog;
