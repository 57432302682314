import React from 'react';
import { TextSection, HighFiveComponent, WeCoverWhatMatters, BigSurSection } from './Components';
import { ReactComponent as RoundedDivider } from '../../assets/images/Rounded-Divider.svg';
import { ReactComponent as ColorfulDivider } from '../../assets/images/Colorful-Divider.svg';
import QuoteForm from '../utilities/QuoteForm';
import { PaymentInfoSection } from '../utilities/PaymentInfo';

const HomePage: React.FC = () => {
  return (
    <div>
      <div className="relative">
        <img src="https://bay-and-basin-bucket.s3.amazonaws.com/Home-Background.webp" 
             alt="Homepage Background" 
             className="w-full min-h-screen object-cover" />  {/* Ensure the image covers full screen height minimum */}

        {/* Overlay container for text and image with responsive adjustments */}
        <div className="absolute inset-x-0 top-10 md:top-20 z-10 
                      flex flex-col md:flex-row justify-between items-center w-full px-4 md:px-8">
          {/* Text Section aligned to the left with responsive alignment */}
          <div className="flex-1 flex justify-center md:justify-start items-center px-4 py-2">
            <TextSection />
          </div>
          
          {/* Image Section aligned to the right with responsive visibility */}
          <div className="flex-1 flex justify-center md:justify-end items-center mt-10 md:mt-0 px-4 py-2">
            <HighFiveComponent highFiveImage="https://bay-and-basin-bucket.s3.amazonaws.com/HighFive.webp" />
          </div>
        </div>

        {/* Positioning for QuoteForm UI component with responsive adjustments */}
        <div className="absolute inset-x-0 top-1/2 md:top-3/4 z-20 
                      transform -translate-y-1/2 md:translate-y-0 px-4">
          <QuoteForm />
        </div>
        <div className="absolute bottom-0 left-1/2 z-10 transform -translate-x-1/2 translate-y-1/2" 
             style={{ width: '90%' }}>
          <RoundedDivider />
        </div>
      </div>
      <div className="px-4 py-8">
        <WeCoverWhatMatters />
        <PaymentInfoSection />
        <ColorfulDivider className="w-full" />
        <BigSurSection />
      </div>
    </div>
  );
};

export default HomePage;
 