import React from "react";
import { ReactComponent as PaymentsSVG } from "../../assets/images/homepage/Payments.svg";
import { ReactComponent as LogoWatermark } from '../../assets/images/Logo-Watermark.svg';

export const PaymentInfoSection: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row justify-around items-center my-20 relative mt-16"> {/* Responsive flex-direction */}
      <div className="text-center px-4 mb-10 md:mb-0"> {/* Responsive padding and margin */}
        <p className="text-sunset-light-orange text-3xl md:text-4xl font-regular tracking-[.1em] md:tracking-[.2em]">Now accepting</p>
        <p className="text-sea-blue text-3xl md:text-5xl font-regular tracking-[.1em] md:tracking-[.2em]">DIGITAL<br />PAYMENTS!</p>
      </div>
      <div className="hidden md:block w-1 h-32 bg-sea-blue mx-4"></div> {/* Adjusted visibility and size of divider */}
      <div className="text-center px-4"> {/* Responsive padding */}
        <p className="text-sunset-light-orange text-lg md:text-xl font-light mb-4 tracking-[.1em] md:tracking-[.2em]">It's fast, simple, and secure!<br/>
        <span className="text-sea-blue tracking-[.1em] md:tracking-[.2em]">Easily pay premiums via ACH or credit card</span></p>
        <PaymentsSVG className="w-1/2 md:w-1/4 mx-auto" /> {/* Responsive SVG size */}
        <button
          onClick={() => window.open('https://bayandbasinins.epaypolicy.com/', '_blank')}
          className="mt-4 bg-sea-blue text-white py-2 px-8 md:px-12 font-light tracking-[.1em] md:tracking-[.2em] text-xs md:text-sm rounded-full"
        >
          Make a Payment
        </button>
      </div>
      {/* Responsively position Logo Watermark to not overlap content on smaller screens */}
      <div className="absolute right-0 translate-x-1/4 md:translate-x-1/2 top-1/2 -translate-y-1/2">
        <LogoWatermark className="w-auto h-60 md:h-80 opacity-50" /> {/* Adjust size and opacity */}
      </div>
    </div>
  );
};
