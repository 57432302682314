import React from 'react';
import { ImageInfo } from '../utilities/ImageWithOverlay';
import ImagePairWithOverlay from '../utilities/ImagePairWithOverlay';

const InsuranceServicesHeader = () => {

  const businessImage: ImageInfo = {
    src: "https://bay-and-basin-bucket.s3.amazonaws.com/business-insurance.webp",
    overlayColor: "#799daa",
    opacity: 0.8,
    text: "BUSINESS INSURANCE",
    textPosition: 'bottom',
    href: '/insurance-services/business-insurance',
    isVisible: true
};

const lifeImage: ImageInfo = {
    src: "https://bay-and-basin-bucket.s3.amazonaws.com/IS_life_right.webp",
    overlayColor: "#FBAC46",
    opacity: 0.8,
    text: "LIFE & MORE",
    textPosition: 'bottom',
    href: '/insurance-services/life-and-more',
    isVisible: true
};
  return (
    <div className="flex w-full">
      {/* Business Insurance Image */}
        <ImagePairWithOverlay image1={businessImage} image2={lifeImage} />
      </div>);
};

export default InsuranceServicesHeader;
