import React from 'react';

interface TextSizeOptions {
  base: string;
  large: string;
  small: string;
}

interface AccessibilityControlsProps {
  onClose: () => void; // Prop for closing the modal
}

const AccessibilityControls: React.FC<AccessibilityControlsProps> = ({ onClose }) => {
  const changeTextSize = (size: TextSizeOptions) => {
    document.documentElement.style.setProperty('--text-base-size', size.base);
    document.documentElement.style.setProperty('--text-lg-size', size.large);
    document.documentElement.style.setProperty('--text-sm-size', size.small);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center p-4">
      <div className="p-6 bg-gray-800 text-white rounded-lg shadow-lg space-y-4">
        <h2 className="text-xl font-semibold">Adjust Text Size</h2>
        <p className="text-sm">Select a text size that best fits your reading preference:</p>
        <div className="flex flex-col space-y-2">
            <button
            className="px-4 py-2 bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-700"
            onClick={() => changeTextSize({ base: '0.75rem', large: '0.875rem', small: '0.625rem' })}
          >
            Small Size
          </button>
          <button
            className="px-4 py-2 bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700"
            onClick={() => changeTextSize({ base: '1rem', large: '1.125rem', small: '0.875rem' })}
          >
            Default Size
          </button>
          <button
            className="px-4 py-2 bg-sea-blue rounded hover:bg-wave-blue focus:outline-none focus:ring-2 focus:ring-green-700"
            onClick={() => changeTextSize({ base: '1.25rem', large: '1.5rem', small: '1rem' })}
          >
            Large Size
          </button>
          <button
            className="px-4 py-2 bg-green-500 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700"
            onClick={() => changeTextSize({ base: '2.5rem', large: '3rem', small: '2rem' })}
          >
            Extra Large Size
            </button>
        </div>
        <button
          className="mt-4 px-4 py-2 bg-gray-700 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AccessibilityControls;
