import React from "react";

interface CloudBubbleProps {
    imageUrl: string;
}

const CloudBubble: React.FC<CloudBubbleProps> = ({imageUrl}) => {
    return (
    <div style={{ width: '56vw', height: 'auto' }}> {/* Use viewport units for responsive sizing */}
        <svg id="CloudBubble_2" data-name="Cloud Bubble 2" xmlns="http://www.w3.org/2000/svg" viewBox="-100 0 886.29 413.58">
            <defs>
            <clipPath id="clippath-cloud">
                <path d="M711.86,211.21c31.46,18.67,52.56,52.98,52.56,92.21c0,59.18-47.98,107.16-107.16,107.16H333.72c-59.18,0-107.16-47.98-107.16-107.16c0-35.28,17.05-66.58,43.36-86.1H110.16c-59.18,0-107.16-47.98-107.16-107.16c0-59.18,47.98-107.16,107.16-107.16h565.97c59.18,0,107.16,47.98,107.16,107.16c0,46.65-29.81,86.33-71.43,101.05Z" />
            </clipPath>
            </defs>
            <image clipPath="url(#clippath-cloud)" href={imageUrl} width="100%" height="100%" preserveAspectRatio="xMidYMid slice" />
            <path fill="none" stroke="#3b5572" strokeMiterlimit="10" strokeWidth="6" d="M711.86,211.21c31.46,18.67,52.56,52.98,52.56,92.21c0,59.18-47.98,107.16-107.16,107.16H333.72c-59.18,0-107.16-47.98-107.16-107.16c0-35.28,17.05-66.58,43.36-86.1H110.16c-59.18,0-107.16-47.98-107.16-107.16c0-59.18,47.98-107.16,107.16-107.16h565.97c59.18,0,107.16,47.98,107.16,107.16c0,46.65-29.81,86.33-71.43,101.05Z"/>
        </svg>
      </div>
    )
}

export default CloudBubble;